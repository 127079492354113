<template>
  <div class="student-details-questions-cards">
    <div class="student-details-questions-cards__title">
      <h4> Desempenho por questão </h4>
      <span>{{ questionsCount }}</span>
    </div>
    <div
      v-for="(question, index) in questions"
      :key="index"
      class="student-details-questions-cards__item"
      @click="selectQuestion(question, index)"
    >
      <div class="student-details-questions-cards__item__top-row">
        <div class="student-details-questions-cards__item__detail">
          <span class="student-details-questions-cards__item__detail__number">
            {{ question.number }}
          </span>
          <span class="student-details-questions-cards__item__detail__content">
            {{ question.statement | truncate(statementMaxLength) }}
          </span>
        </div>
      </div>
      <div class="student-details-questions-card__divider" />

      <div class="student-details-questions-cards__item__bottom-row">
        <div class="student-details-questions-cards__item__level">
          <span> Dificuldade </span>
          <p> {{ $t(`levels.${question.level}`) }}</p>
        </div>
        <div class="student-details-questions-cards__item__level">
          <span> Alternativa escolhida</span>
          <p> {{ letterIndex(question.markedOption) }} </p>
        </div>
        <div class="student-details-questions-cards__item__level">
          <span> Alternativa correta</span>
          <p> {{ letterIndex(question.answerKey) }} </p>
        </div>
        <div class="student-details-questions-cards__item__hit">
          <AnswerStatusBadge
            :row="question"
            :icon="badgeIcon(question.answerStatus)"
            :variation="badgeVariation(question.answerStatus)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import badgeStyle from 'App/mixins/badgeStyle'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import simplur from 'simplur'
import truncate from 'shared/utils/truncateText'
import AnswerStatusBadge from './components/AnswerStatusBadge'

export default {
  name: 'StudentDrawerQuestionsCards',
  components: {
    AnswerStatusBadge,
  },
  mixins: [ mediaQueries, badgeStyle, subjectVisibilityToggle ],
  props: {
    questions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 25
        case 'small':
          return 32
        case 'medium':
          return 58
        case 'large':
          return 68
        case 'x_large':
          return 100
        default:
          return 68
      }
    },
    questionsCount() {
      return simplur`${this.questions.length} [questão|questões]`
    },
  },
  methods: {
    truncate,
    selectQuestion(data, index) {
      this.$emit('selectQuestion', {
        data,
        index,
      })
    },
    letterIndex(index) {
      const options = [ 'A', 'B', 'C', 'D', 'E' ]

      return options[index] || ''
    },
  },
}
</script>
<style lang="scss" scoped>
.student-details-questions-card__divider {
  height: 1px;
  background-color: #E6E9ED;
}

.student-details-questions-cards {
  font-family: "Inter";
  background: $color-white;
  padding: $size-m;
  border: 1px solid #E6E9ED;
  border-radius: 16px;

  &__title {
    margin-bottom: 1rem;
  }

  &__item {
    @include space-stack($size-s);
    border: 1px solid #E6E9ED;
    border-radius: 16px;
    padding: $size-s;
    display: flex;
    flex-direction: column;

    &__top-row {
      @include flex-center-start;
      @include space-stack($size-s);
    }

    &__bottom-row {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
    }

    &__detail {
      flex-grow: 1;
      display: flex;

      &__number {
        color: #191C1F;
        background-color: #E6E9ED;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $size-xxs;
      }

      &__content {
        color: #191C1F;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &__level {
      display: flex;
      flex-direction: column;

      span {
        color: #707780;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.5px;
      }
    }

    &__hit {
      margin-left: $size-m;
    }
  }
}
</style>
