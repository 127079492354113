<template>
  <div class="essential-learning-card__item">
    <div class="essential-learning-card__item__question">
      <h6 class="essential-learning-card__item__question__statement">
        {{ truncateText(topic.title, statementMaxLength) }}
      </h6>
    </div>

    <div class="essential-learning-card__divider" />

    <div class="essential-learning-card__item__details">
      <div class="essential-learning-card__item__details-levels">
        <span> Percentual de acerto </span>
        <p> {{ topic.learningPercentage }}% </p>
      </div>
    </div>
  </div>
</template>

<script>
import truncateText from 'shared/utils/truncateText'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'QuestionsCard',
  mixins: [ subjectVisibilityToggle, mediaQueries ],
  props: {
    topic: {
      type: Object,
      required: true,
    },
    questionNumberIsVisible: Boolean,
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 16
        case 'small':
          return 28
        case 'medium':
          return 43
        case 'large':
          return 60
        case 'x_large':
          return 90
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
  },
  methods: {
    truncateText,
  },
}
</script>

<style lang="scss" scoped>
.essential-learning-card__divider {
  width: 1px;
  background-color: #E6E9ED;
  margin: 0 16px;
}

.essential-learning-card__item {
  @include space-stack($size-s);
  border: 1px solid #E6E9ED;
  border-radius: $size-xs;
  display: flex;

  &__number {
    width: $size-m;
    height: $size-m;
    background-color: #E6E9ED;
    color: #525860;
    border-radius: $size-l;
    @include flex-center;
    flex-shrink: 0;
    margin: 0 $size-s;

    span {
      font-weight: 600;
    }
  }

  &__question {
    flex-grow: 1;
    padding: $size-s;
    left: 12px;
    display: flex;
    align-items: center;

    &__content {
      margin-bottom: $size-xs;
      color: $color-ink-light;
      display: inline-block;
    }
  }

  &__details {
    min-width: $size-xxl;
    padding: $size-s;
    display: flex;

    &-levels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: "Inter";

      span {
        color: #707780;
      }

      p {
        font-weight: 600;
        font-size: $font-size-s;
        color: #191C1F;
      }
    }

    &-hit-rate {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        color: #707780;
      }

      b {
        font-weight: 600;
        font-size: $font-size-s;
        color: #191C1F;
      }
    }
  }
}
</style>
