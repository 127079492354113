<template>
  <div class="students-view">
    <StudentsViewHeader
      :loading="loading"
      :students-count="studentsCount"
    />
    <StudentsViewSearch
      :loading="loading"
      :custom-filter-options="studentsFilterOptions"
      :is-arena-mission="isArenaMission"
    />
    <template v-if="mq_l">
      <template v-if="loading">
        <StudentsViewCardsSkeleton />
      </template>
      <template v-else>
        <StudentsViewCards
          :students="filteredStudents"
          @selectStudent="selectStudent"
        />
      </template>
    </template>
    <template v-else>
      <template v-if="loading">
        <StudentsViewTableSkeleton />
      </template>
      <template v-else>
        <StudentsViewTable
          ref="studentsTable"
          :active-student="activeStudent"
          :students="filteredStudents"
          :is-arena-mission="isArenaMission"
          @selectStudent="selectStudent"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { studentAssignmentStatus } from 'App/utils/status'
import accentFold from 'App/mixins/accentFold'
import mediaQueries from 'shared/mixins/mediaQueries'
import simplur from 'simplur'
import { orderBy, isEmpty } from 'lodash'
import StudentsViewHeader from './StudentsViewHeader'
import StudentsViewSearch from './StudentsViewSearch'
import StudentsViewCards from './StudentsViewCards'
import StudentsViewCardsSkeleton from './StudentsViewCardsSkeleton'
import StudentsViewTable from './StudentsViewTable'
import StudentsViewTableSkeleton from './StudentsViewTableSkeleton'
import visualizeByEnum from './enums/visualizeBy'
import assessmentFilter from './utils/assessmentFilter'
import assessmentStatus from './utils/assessmentStatus'
import assessmentStatusEnum from './enums/assessmentStatus'
import sortStudents from './utils/sortStudents'

export default {
  name: 'StudentsView',
  components: {
    StudentsViewHeader,
    StudentsViewSearch,
    StudentsViewCards,
    StudentsViewCardsSkeleton,
    StudentsViewTable,
    StudentsViewTableSkeleton,
  },
  mixins: [
    mediaQueries,
    accentFold,
  ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    selectedClassroom: {
      type: Object,
      default: () => ({}),
    },
    isChallengeActivity: Boolean,
    isTeacherChallenge: Boolean,
    isChallengeReinforcement: Boolean,
    loading: Boolean,
    studentsStatistics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchString: '',
      activeStatusFilter: 'all',
      activeSort: null,
      activeStudentInnerIndex: -1,
    }
  },
  computed: {
    isArenaMission() {
      return this.isChallengeActivity || this.isTeacherChallenge || this.isChallengeReinforcement
    },
    studentsCount() {
      return this.studentsStatistics.data?.length ?? 0
    },
    students() {
      const visualizeByParam = this.$route.query?.visualizeBy ?? visualizeByEnum.SESSION_MORE_RECENT

      return this.mappedStatistics(this.studentsStatistics?.data, visualizeByParam)
    },
    filteredStudents() {
      const students = this.students.filter((student) => (
        (
          this.activeStatusFilter === 'all'
          || student.status === this.activeStatusFilter
        )
        && this.accentFold(student.name.toLocaleLowerCase())
          .includes(
            this.accentFold(this.searchString.toLocaleLowerCase())
          )
      ))

      if (this.activeSort && this.mq_m) {
        const [ field, direction ] = this.activeSort.split(',')
        const modifier = direction === 'asc' ? 1 : -1
        students.sort((a, b) => {
          const order = sortStudents[field](
            a[field],
            b[field],
            modifier
          )
          if (order !== 0) return order

          return sortStudents.name(a.name, b.name)
        })
      }

      return students
    },
    activeStudent() {
      const { studentId } = this.$route.params
      if (!studentId) return null

      const [ student ] = this.students.filter((item) => (
        item.id === Number.parseInt(studentId, 10)
      ))

      return student
    },
    activeStudentIndex() {
      return this.filteredStudents.findIndex((student) => (
        student.id === this.activeStudent?.id
      ))
    },
    hasPreviousStudent() {
      return this.activeStudentInnerIndex > 0
    },
    hasNextStudent() {
      return this.activeStudentInnerIndex < this.filteredStudents.length - 1
    },
    activeStudentInfo() {
      if (!this.activeStudent) return null
      const studentShift = this.$t(`shifts.${this.selectedClassroom.shift}`)

      return {
        title: this.activeStudent.name,
        subtitle:
          `${this.selectedClassroom.grade.name} · ${this.selectedClassroom.name} · ${studentShift}`,
      }
    },
    subject() {
      if (!this.selectedClassroom) return null

      const lectureId = parseInt(this.$route.query.lectureId, 10)
      const classroomLectures = this.selectedClassroom.lectures

      return classroomLectures.find(({ id }) => id === lectureId)?.subject
    },
    studentsFilterOptions() {
      if (this.isChallengeActivity) {
        return [
          {
            value: 'all',
            label: 'Todos os status',
          },
          {
            value: studentAssignmentStatus.FINISHED,
            label: studentAssignmentStatus.FINISHED,
          },
          {
            value: studentAssignmentStatus.NOT_STARTED,
            label: studentAssignmentStatus.NOT_STARTED,
          },
        ]
      }

      return []
    },
  },
  watch: {
    '$route.query': 'setFilterParams',
  },
  mounted() {
    this.setFilterParams(this.$route.query)
  },
  methods: {
    setFilterParams(query) {
      const {
        name,
        status,
        sort,
      } = query
      this.searchString = name || ''
      this.activeStatusFilter = status || 'all'
      this.activeSort = sort || ''
    },
    selectStudent({ data, index }) {
      const { id } = data
      const { assignmentCode } = data

      this.activeStudentInnerIndex = index
      this.$router.push({
        name: 'mfe-student-details',
        params: {
          studentId: id,
        },
        query: {
          assignmentCode,
          classroomId: this.selectedClassroom.id,
          questionnaireCode: this.questionnaire.data.code,
          studentId: id,
        },
      })
    },
    previousStudent() {
      if (this.mq_m && this.activeStudentIndex > 0) {
        this.selectStudent({
          data: this.filteredStudents[this.activeStudentIndex - 1],
          index: this.activeStudentIndex - 1,
        })
      }
    },
    nextStudent() {
      if (this.mq_m && this.activeStudentIndex < this.filteredStudents.length - 1) {
        this.selectStudent({
          data: this.filteredStudents[this.activeStudentIndex + 1],
          index: this.activeStudentIndex + 1,
        })
      }
    },
    retryCountDescription(retryCount) {
      return simplur`${retryCount}`
    },
    mappedStatistics(statistics, param) {
      if (isEmpty(statistics)) {
        return []
      }

      return statistics?.map((statistic) => {
        const hasNoAssignment = (
          isEmpty(statistic.assignments)
          || statistic?.assignments?.some((assignment) => assignment?.status === 'NOT_STARTED')
        ) ?? false
        const emptyStatus = assessmentStatus(null)

        if (hasNoAssignment) {
          return {
            id: statistic.student.id,
            name: statistic.student.name,
            status: emptyStatus,
            finishedAt: null,
            hitRate: null,
            assignmentCode: null,
            retryCount: null,
            retryCountText: '-',
            assignments: [],
            questionsCount: null,
          }
        }

        const assignment = assessmentFilter(statistic?.assignments, param)
        const status = assessmentStatus(assignment)

        const isFinished = status === assessmentStatusEnum.FINISHED
        const finishedAt = isFinished ? new Date(assignment?.finishedAt) : null

        const moreRecentAssignment = assessmentFilter(
          statistic?.assignments, visualizeByEnum.SESSION_MORE_RECENT
        )
        const retryCount = moreRecentAssignment?.retryCount ?? 0
        const retryCountText = isFinished ? this.retryCountDescription(retryCount) : '-'

        const sortAssignments = orderBy(statistic?.assignments, [ 'retryCount' ], [ 'asc' ])

        return {
          id: statistic.student.id,
          name: statistic.student.name,
          status,
          finishedAt,
          hitRate: assignment?.hitRate,
          assignmentCode: assignment?.code,
          retryCount,
          retryCountText,
          assignments: sortAssignments,
          questionsCount: statistic.questionsCount,
        }
      })
    },
  },
}
</script>
