<template>
  <div class="filter-control">
    <SSelect
      v-model="selectedLevel"
      size="large"
      variation="secondary"
      track-by="label"
      :icon-right="iconRight"
      :placeholder="'Todas as dificuldades'"
      :options="levelOptions"
      width="235px"
      @select="$emit('onLevelSelect', $event.value)"
    />
    <OrderSelector
      @change-option="setSort"
    />
  </div>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import SSelect from 'MFE/components/SSelect'
import OrderSelector from '../../../OrderSelector'

export default {
  name: 'FilterControl',
  components: { OrderSelector, SSelect },
  mixins: [ mediaQueries ],
  props: {
    levelFilter: {
      type: [ String, Object ],
      required: true,
    },
    sortOption: {
      type: Object,
      required: true,
      validator: (value) => value.field && value.direction,
    },
    questionnaire: {
      type: Object,
      default: () => ({}),
    },
    isFilteredByOrder: Boolean,
  },
  data() {
    return {
      isDrawerVisible: false,
      selectedLevel: this.levelFilter,
    }
  },
  computed: {
    isChallengeActivity() {
      if (!this.questionnaire.data) return false

      return this.questionnaire.data.isChallengeActivity
    },
    iconRight() {
      return this.mq_xl__mf ? 'chevron-down' : null
    },
    isExtraActivity() {
      if (!this.questionnaire.data) return false

      const { isExtraActivity, isEnemByDifficulty } = this.questionnaire.data

      return isExtraActivity || isEnemByDifficulty
    },
    levelOptions() {
      return [
        { value: 'all', label: 'Todas as dificuldades' },
        { value: 'easy', label: 'Fácil' },
        { value: 'medium', label: 'Médio' },
        { value: 'hard', label: 'Difícil' },
      ].map(({ value, label }) => ({ value, label }))
    },
    levelFilterObject() {
      return this.levelOptions.find((level) => level.value === this.levelFilter)
    },
    sortOptions() {
      const sortOptions = [
        {
          field: 'hitRate',
          direction: 'desc',
        },
        {
          field: 'hitRate',
          direction: 'asc',
        },
      ]

      if (this.isFilteredByOrder) {
        sortOptions.unshift({ field: 'number', direction: 'asc' })
      }

      return sortOptions.map((sortOption) => ({
        value: sortOption,
        label: this.getSortOptionLabel(sortOption),
        id: `${sortOption.field}${sortOption.direction}`,
      }))
    },
    sortOptionObject() {
      return this.sortOptions.find(
        (option) => (
          option.value.field === this.sortOption.field
          && option.value.direction === this.sortOption.direction
        )
      )
    },
  },
  watch: {
    selectedLevel(newValue) {
      const selectedValue = typeof newValue === 'object' ? newValue.value : newValue
      this.$emit('onLevelSelect', selectedValue)
    },
    levelFilter(newLevelFilter) {
      this.selectedLevel = typeof newLevelFilter === 'string'
        ? this.levelOptions.find((option) => option.value === newLevelFilter)
        : newLevelFilter
    },
  },
  methods: {
    setSort(order) {
      this.$emit('onSortSelect', order)

      this.sort = order
    },
    getSortOptionLabel({ field, direction }) {
      const optionsLabel = {
        number: {
          asc: this.isChallengeActivity || this.isExtraActivity
            ? 'Ordem original da atividade'
            : 'Ordem da questão no livro',
        },
        hitRate: {
          asc: 'Menor índice de acerto',
          desc: 'Maior índice de acerto',
        },
      }

      return optionsLabel[field][direction]
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-control {
  font-family: "Inter";
  font-weight: 600;

  &__label {
    white-space: nowrap;
    margin-right: $size-s;
  }

  @include mq-m--mf {
    display: flex;
  }

  @include mq_m {
    width: 361px;
    gap: $size-xs;
  }

  @include mq_s {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $size-s;
  }

  ::v-deep .sas-dropdown-menu {
    left: auto;
    right: 0;
    min-width: 120px;
  }

  .sas-select {
    margin-right: $size-s;
    min-width: 235px;

    @include mq_l {
      margin-right: 0;
      min-width: unset;
    }

    @include mq_s {
      width: 100%;
    }
  }

  .order-selector__wrapper {
    max-height: 44px;

    @include mq_s {
      width: 100%;
    }
  }

  ::v-deep .adaptive-selector-dropdown__handle {
    padding: 8px 16px;
  }

}
</style>
