<template>
  <div :style="{ paddingBottom: '0' }">
    <Header
      :subject-ids="subjectIds"
      title="Relatórios Eureka!"
      subtitle="Analise os relatórios das atividades nas missões Eureka!, Arena Ancestral e Lógos."
      :tab-routes="tabRoutes"
    >
      <SchoolYearSelector @select-year="selectedYear" />

      <template #left>
        <label class="home-header__content__left__label">
          Turma
        </label>
        <ClassPicker
          :selected-option="selectedClassroom"
          :classrooms="classrooms.data"
          :loading="classrooms.loading"
          @select="handleSelectClassroom"
        />
      </template>
      <template #right>
        <SButton
          class="home-header__content__right__button"
          icon-right="external-link"
          @click="goToEureka()"
        >
          Acessar Eureka!
        </SButton>
      </template>
    </Header>
    <router-view :selected-classroom="selectedClassroom" />
    <TeacherOnBoarding
      v-if="onBoarding"
      @teacherOnBoardingDone="teacherOnBoardingDone"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import updateRoute from 'shared/mixins/updateRoute'
import { pickBy, identity } from 'lodash'
import storage from '@/service/storage'
import TeacherOnBoarding from 'shared/components/TeacherOnBoarding/TeacherOnBoarding'
import SchoolYearSelector from 'shared/components/SchoolYearSelector'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import Header from 'MFE/components/Header/Header'
import { urls } from 'shared/utils/urls'
import ClassPicker from './partials/ClassPicker'

const MATH_SUBJECT_ID = 5

export default {
  name: 'Home',
  components: {
    Header,
    TeacherOnBoarding,
    ClassPicker,
    SchoolYearSelector,
  },
  mixins: [ updateRoute ],
  data() {
    return {
      selectedClassroom: null,
      onBoarding: false,
      selectedLecture: null,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'classrooms',
      'yearSelectedContent',
    ]),
    ...mapState({
      isShowAncestralArenaTab: ({ featureToggles }) => featureToggles
        .toggles.isShowAncestralArenaTab,
      isShowRecompositionAvailableReport: ({ featureToggles }) => featureToggles
        .toggles.isShowRecompositionAvailableReport,
    }),
    isMFE() {
      return microfrontendService.get()
    },
    classroomQueryParam() {
      return parseInt(this.$route.query.classroomId, 10)
    },
    lectures() {
      return this.selectedClassroom?.lectures ?? []
    },
    lectureQueryParam() {
      return parseInt(this.$route.query.lectureId, 10) ?? this.selectedClassroom?.lectures[0]?.id
    },
    subjectIds() {
      return this.lectures.map((lecture) => lecture.subject.id)
    },
    classroomId() {
      return parseInt(this.$route.query.classroomId, 10) || null
    },
    lectureId() {
      return parseInt(this.$route.query.lectureId, 10) || null
    },
    hasMathSubjectId() {
      return this.subjectIds.includes(MATH_SUBJECT_ID)
    },
    canAccessLogosTab() {
      return this.hasMathSubjectId
      && this.isShowRecompositionAvailableReport
    },
    tabRoutes() {
      const queryParams = {
        ...this.$route.query,
        lectureId: this.lectureId || null,
        classroomId: this.classroomId || null,
      }

      const filteredQueryParams = pickBy(queryParams, identity)

      const { isMiddleSchoolTeacher } = this.user

      const tabs = [
        {
          name: 'Missões Eureka!',
          route: {
            name: 'mfe-sas-activities',
            query: filteredQueryParams,
          },
          isShow: true,
        },
        {
          name: 'Arena Ancestral',
          route: {
            name: 'mfe-arena-ancestral',
            query: filteredQueryParams,
          },
          isShow: this.isShowAncestralArenaTab && isMiddleSchoolTeacher,
        },
        {
          name: 'Lógos',
          route: {
            name: 'mfe-logos-report',
            query: filteredQueryParams,
          },
          isShow: this.canAccessLogosTab,
        },
      ]

      return tabs.filter(({ isShow }) => isShow)
    },
  },
  watch: {
    selectedClassroom() {
      if (this.selectedClassroom?.id) {
        this.updateRouteQuery('classroomId', this.selectedClassroom.id)
      }
    },
    classrooms() {
      if (this.classrooms.data) {
        if (this.classroomQueryParam) {
          this.setSelectedClassroom(this.classroomQueryParam)
        } else {
          const [ firstClassroom ] = this.classrooms.data

          this.selectedClassroom = firstClassroom
        }
      }
    },
    lectures() {
      this.setSelectLectures()
    },
    selectedLecture() {
      const { selectedLecture } = this

      if (selectedLecture?.id) {
        this.updateRouteQuery('lectureId', selectedLecture.id)
      }
    },
  },
  async created() {
    if (!this.classrooms.data) {
      await this.getClassrooms(this.yearSelectedContent)
    }

    if (!this.selectedClassroom && !!this.classroomQueryParam) {
      this.setSelectedClassroom(this.classroomQueryParam)
    }

    const onBoardedUsers = storage.getItem('@onboarding/Teacher')
    this.onBoarding = !(onBoardedUsers || []).includes(this.user.id)
  },
  methods: {
    ...mapActions([
      'getClassrooms',
      'setYearSelectedContent',
    ]),
    goToEureka() {
      const url = this.isMFE ? `${urls.CROSS_PORTAL_URL}/eureka` : urls.EUREKA_URI
      window.open(url, '_blank')
    },
    handleSelectClassroom(classroom) {
      this.selectedClassroom = classroom
    },
    setSelectLectures() {
      const { findLectureById, lectureQueryParam } = this
      const lectureFound = findLectureById(lectureQueryParam)
      const hasAvailableSelectedLecture = Boolean(lectureFound)

      if (hasAvailableSelectedLecture) {
        this.selectedLecture = lectureFound
      } else {
        const [ firstLecture ] = this.selectedClassroom.lectures

        this.selectedLecture = firstLecture
      }
    },
    teacherOnBoardingDone() {
      const onBoardedUsers = storage.getItem('@onboarding/Teacher') || []
      storage.setItem('@onboarding/Teacher', [
        ...onBoardedUsers,
        this.user.id,
      ])
      this.onBoarding = false
    },
    async selectedYear(year) {
      const { yearSelectedContent } = this

      if (yearSelectedContent !== year) {
        this.setYearSelectedContent(year)

        await this.getClassrooms(year)
      }
    },
    setSelectedClassroom(classroomId) {
      if (classroomId) {
        this.selectedClassroom = this.classrooms.data.find(({ id }) => id === classroomId)
      }
    },
    findLectureById(lectureId) {
      return this.selectedClassroom.lectures.find((lecture) => lecture.id === lectureId)
    },
  },
}
</script>

<style lang="scss">
body {
  height: 100vh;
  background-color: $color-white;
}
</style>

<style scoped lang='scss'>
.home-header {
  &__content {
    &__right {
      &__button {
        height: 44px;

        @include mq_l {
          margin-top: 0;
        }

        @include mq_s {
          width: 100%;
        }
      }
    }

    &__left {
      &__label {
        margin-bottom: $size-xxs;
        display: inline-block;
        color: #191C1F;
        font-weight: 600;
      }
    }
  }
}
</style>
