import Vue from 'vue'
import mainMenuVue from '@sas-te/main-menu-vue'
import alfabeto from '@sas-te/alfabeto-vue'
import '@sas-te/alfabeto-vue/dist/alfabeto.css'
import VueTruncate from 'vue-truncate-filter'
import VueApexCharts from 'vue-apexcharts'

import i18n from '@/i18n'
import store from 'App/store'
import routerService, { getRouter } from '@/shared/router/RouterService'
import App from './App'
import './plugins'
import microfrontendService from './shared/utils/MicrofrontendService'

Vue.use(alfabeto)
Vue.use(VueApexCharts)
Vue.use(mainMenuVue, { store, env: process.env.VUE_APP_MODE })
Vue.use(VueTruncate)
Vue.config.productionTip = false

microfrontendService.set(false)
const appInstance = new Vue({
  router: getRouter(),
  store,
  i18n,
  render: (h) => h(App),
})

appInstance.$options.beforeDestroy = () => {
  routerService.destroy()
  microfrontendService.clear()
}

appInstance.$mount('#app')
