<template>
  <div class="students-view-table">
    <s-table
      ref="studentsTable"
      :content="students"
      :selected-row="activeStudent"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="true"
      @sorted="tableSorted"
      @click-row="clickRow"
    >
      <template
        slot="name"
        slot-scope="{ row }"
      >
        <div class="students-view-table__name">
          <ProfilePicture
            :user="row"
          />
          <h6>
            {{ row.name }}
          </h6>
        </div>
      </template>
      <template
        slot="status"
        slot-scope="{ row }"
      >
        <div class="students-view-table__status">
          <s-badge :variation="getStatusVariation(row.status)">
            {{ getStatusText(row) }}
          </s-badge>
        </div>
      </template>
      <template
        slot="hitRate"
        slot-scope="{ row }"
      >
        <p class="students-view-table__hit-rate">
          <b>{{ row.hitRate | handleHitRate }}</b>
        </p>
      </template>
      <template
        slot="retryCountText"
        slot-scope="{ row }"
      >
        <div class="students-view-table__retry-count">
          {{ row.retryCountText }}
        </div>
      </template>
      <template
        slot="finishedAt"
        slot-scope="{ row }"
      >
        <div class="students-view-table__finished-at">
          <p class="students-view-table__finished-at__date">
            {{ mq_xl__mf
              ? format(row.finishedAt, 'dd MMM, yyyy')
              : format(row.finishedAt, 'dd MMM')
            }}
          </p>
          <span
            v-if="mq_xl__mf && row.finishedAt"
            class="students-view-table__finished-at__time"
          >
            - {{ format(row.finishedAt, 'HH:mm') }}
          </span>
        </div>
      </template>
      <template
        slot="pagination"
        slot-scope="props"
      >
        {{ $tc('report.students.pagination',
               props.total,
               {
                 from: props.start + 1,
                 to: props.end,
                 total: props.total
               }) }}
      </template>
    </s-table>
  </div>
</template>

<script>
import { getStatusVariation } from 'MFE/utils/status'
import mediaQueries from 'shared/mixins/mediaQueries'
import formatDate from 'App/mixins/formatDate'
import STable from 'MFE/components/STable'
import { isNaN, isNil } from 'lodash'
import ProfilePicture from 'MFE/components/ProfilePicture'

export default {
  name: 'StudentsViewTable',
  components: {
    STable,
    ProfilePicture,
  },
  filters: {
    handleHitRate: (hitRate) => {
      if (isNaN(hitRate) || isNil(hitRate)) return '-'

      return `${hitRate}%`
    },
  },
  mixins: [
    mediaQueries,
    formatDate,
  ],
  props: {
    students: {
      type: Array,
      required: true,
    },
    activeStudent: {
      type: Object,
      default: null,
      required: false,
    },
    isArenaMission: Boolean,
  },
  data() {
    return {
      defaultSort: {
        field: 'name',
        direction: 'asc',
      },
    }
  },
  computed: {
    fields() {
      const fields = [
        {
          value: 'name',
          text: 'Estudante',
          sortable: true,
          width: '500px',
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'status',
          text: 'Status',
          sortable: true,
        },
        {
          value: 'hitRate',
          text: 'Percentual de acerto',
          sortable: true,
          sortFunction: (a, b, modifier = 1) => {
            if (a === null && b !== null) return 1
            if (a !== null && b === null) return -1
            if (a > b) return modifier
            if (a < b) return -1 * modifier

            return 0
          },
        },
      ]

      if (!this.isArenaMission) {
        fields.push({
          value: 'retryCountText',
          text: 'Sessões',
          sortable: false,
        })
      }

      fields.push({
        value: 'finishedAt',
        text: 'Finalizado',
        sortable: true,
        sortFunction: (a, b, modifier = 1) => {
          if ((a ? a.getTime() : 0) > (b ? b.getTime() : 0)) return modifier
          if ((a ? a.getTime() : 0) < (b ? b.getTime() : 0)) {
            return -1 * modifier
          }

          return 0
        },
      })

      return fields
    },
  },
  created() {
    if (this.$route.query.sort) {
      const [ field, direction ] = this.$route.query.sort.split(',')
      this.defaultSort = {
        field,
        direction,
      }
    }
  },
  methods: {
    getStatusVariation,
    getStatusText({ status }) {
      if (status === 'Não iniciado') {
        return 'Não iniciado'
      }

      return `• ${status}`
    },
    tableSorted(sort) {
      this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          sort: `${sort.field},${sort.direction}`,
        },
      })
    },
    clickRow({ data, dataIndex }) {
      this.$emit('selectStudent', {
        data,
        index: dataIndex,
      })
    },
    previousStudent() {
      this.$refs.studentsTable.previous()
    },
    nextStudent() {
      this.$refs.studentsTable.next()
    },
  },
}
</script>

<style lang="scss" scoped>
.students-view-table {
  font-family: "Inter";

  &__finished-at {
    white-space: nowrap;

    p {
      display: inline-block;
    }
  }

  &__name {
    display: flex;
    gap: $size-s;
    align-items: center;
    h6 {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__hit-rate, &__retry-count, &__finished-at {
    white-space: nowrap;
    text-align: center;
  }

  ::v-deep .sas-badge__text {
    white-space: nowrap;
  }

  ::v-deep .sas-table__body tr {
    cursor: pointer;
  }

  ::v-deep .sas-badge--neutral {
    background: $color-ink-lightest;
    color: $color-ink;
    border-radius: 100px;
    opacity: 0.75;
  }

  ::v-deep .sas-badge--success {
    color: #00702F;
    background: none;
  }

  ::v-deep .sas-badge--warning {
    color: #0F56B3;
    background: none;
  }

  ::v-deep .sas-badge--neutral {
    background: none;
    color: #707780;
  }

  ::v-deep .sas-table__header__field {
    justify-content: space-between;
  }

  ::v-deep .sas-table__header tr th:last-child,
  ::v-deep .sas-table__body tr td:last-child {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
