import { render, staticRenderFns } from "./QuestionDrawerBodySkeleton.vue?vue&type=template&id=42a36a0f&scoped=true"
import script from "./QuestionDrawerBodySkeleton.vue?vue&type=script&lang=js"
export * from "./QuestionDrawerBodySkeleton.vue?vue&type=script&lang=js"
import style0 from "./QuestionDrawerBodySkeleton.vue?vue&type=style&index=0&id=42a36a0f&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a36a0f",
  null
  
)

export default component.exports