<template>
  <div class="question-drawer-body">
    <RichTextViewer
      class="question-drawer-body__statement"
      :text="question.statement"
    />
    <ul class="question-drawer-body__options">
      <li
        v-for="(option, index) in question.options"
        :key="index"
        class="question-drawer-body__options__item"
        :class="[getOptionProperties(option).style]"
      >
        <div class="question-drawer-body__options__item-badge">
          <s-badge
            v-if="option.id === question.answerKey || option.id === question.markedOption"
            :variation="getOptionProperties(option).variation"
            :icon-left="getOptionProperties(option).icon"
          >
            Alternativa {{ getOptionProperties(option).text }}
          </s-badge>

          <s-badge
            v-if="option.id === question.markedOption && option.id !== question.answerKey"
            variation="primary"
          >
            Escolhida
          </s-badge>
        </div>
        <div class="question-drawer-body__options__item__body">
          <div class="question-drawer-body__options__item__key">
            <p :variation="getOptionProperties(option).variation">
              {{ optionLetter[Number.parseInt(option.id, 10)] }})
            </p>
          </div>
          <RichTextViewer
            class="question-drawer-body__options__item__text"
            :text="option.description"
          />
        </div>
      </li>
    </ul>

    <template>
      <div>
        <QuestionDrawerAccordion
          :title="'Explicação'"
        >
          <RichTextViewer :text="question.explanation" />
        </QuestionDrawerAccordion>
        <QuestionDrawerAccordion
          :title="'Habilidades BNCC'"
          :content="question.skill.name"
        />
      </div>
    </template>
    <div
      v-if="question.count"
      class="question-drawer-body__chart"
    >
      <h4>Percentual de acertos da questão</h4>
      <div class="question-drawer-body__chart__apexchart">
        <ApexCharts
          type="bar"
          :options="chartOptions"
          :series="chartSeries"
          height="60px"
          width="580px"
        />
        <span> {{ percentageStudents }} </span>
      </div>

      <p class="question-drawer-body__chart__label">
        {{ question.hitCount }} de {{ question.count }} alunos acertaram a questão
      </p>
    </div>
  </div>
</template>

<script>
import RichTextViewer from 'App/components/RichTextViewer'
import mediaQueries from 'shared/mixins/mediaQueries'
import ApexCharts from 'vue-apexcharts'
import QuestionDrawerAccordion from './QuestionDrawerAccordion'

export default {
  name: 'QuestionDrawerBody',
  components: {
    RichTextViewer,
    QuestionDrawerAccordion,
    ApexCharts,
  },
  mixins: [ mediaQueries ],
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionLetter: [ 'a', 'b', 'c', 'd', 'e' ],
    }
  },
  computed: {
    chartSeries() {
      return [
        {
          name: 'Acertos',
          data: [ this.percentageStudents ],
        },
      ]
    },
    percentageStudents() {
      if (!this.question.hitCount) {
        return '0%'
      }

      return `${(this.question.hitCount / this.question.count) * 100}%`
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            barHeight: '10px',
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: [ '#54C985' ],
          opacity: 1,
        },
        xaxis: {
          max: 100,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          show: false,
        },
        yaxis: {
          show: false,
        },

        grid: {
          show: false,
          borderColor: '#fff',
          row: {
            colors: [ '#F7F9FA' ],
          },
        },
        tooltip: {
          enabled: false,
        },
      }
    },
    hasMoreDetails() {
      return Boolean(this.question.skill)
    },
    skillContent() {
      return this.question.skill
        && this.question.skill.content
        ? this.question.skill.content.name
        : '-'
    },
    skill() {
      return this.question.skill
        ? this.question.skill.name
        : '-'
    },
  },
  methods: {
    getOptionProperties(option) {
      const properties = {
        variation: 'neutral',
        style: 'neutral',
        text: '',
        icon: '',
      }

      if (option.id === this.question.answerKey) {
        properties.variation = 'success'
        properties.style = 'option correct'
        properties.text = 'correta'
        properties.icon = 'check'
      } else if (option.id === this.question.markedOption) {
        properties.variation = 'danger'
        properties.style = 'option wrong'
        properties.text = 'incorreta'
        properties.icon = 'x'
      }

      return properties
    },

  },
}
</script>

<style lang="scss" scoped>
.question-drawer-body {
  overflow-x: auto;
  padding-bottom: 100px;

  &__header {
    padding: $size-l $size-l 0;
    margin-bottom: $size-m;
    @include flex-space-between;

    &__skill {
      ::v-deep .sas-badge.sas-badge--neutral {
        background: $color-white;
        border: 1px solid $color-ink-lighter;
      }
    }
  }

  .option {
    padding: $size-s;
    margin-left: -16px;
    border-radius: $size-xs;
  }

  .correct {
    border: 1px solid green;
  }

  .wrong {
    border: 1px solid red;
  }

  &__statement,
  &__explanation {
    ::v-deep img {
      @include embedded-image;
    }

    ::v-deep p {
      @include embedded-paragraph;
    }
  }

  &__statement {
    padding: 0 $size-xl;
    margin-bottom: $size-m;
  }

  &__options {
    padding: 0 $size-xl;
    margin-bottom: $size-xl;

    &__item {
      margin-bottom: $size-s;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__body {
        display: flex;
      }

      &-badge {
        display: flex;
        margin-bottom: 8px;
        gap: $size-s;
      }

      &__key {
        margin-right: $size-s;

        ::v-deep .sas-badge {
          border: 1px solid transparent;

          &.sas-badge--neutral {
            background: $color-white;
            border-color: $color-ink-lighter;
          }
        }
      }
    }
  }

  &__chart {
    border: 1px solid #E6E9ED;
    background: #FFFFFF;
    border-radius: $size-m;
    padding: $size-l;
    margin: $size-m 2rem;

    &__apexchart {
      display: flex;
      align-items: center;

      span {
        font-weight: 600;
      }
    }

    &__label {
      font-size: 14px;
      font-weight: 400;
      color: #707780;
    }
  }

  &__explanation {
    border-top: 1px solid $color-ink-lightest;
    padding: $size-l;
    width: fit-content;
    min-width: 100%;

    &__title {
      margin-bottom: $size-s;
      h4 {
        font-family: "Inter";
      }
    }

    &__video {
      @include proportional-video;

      ::v-deep iframe {
        @include proportional-video-iframe;
      }
    }
  }
}
</style>
