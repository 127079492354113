<template>
  <div class="filters-bar">
    <skeleton-loader
      v-if="loading"
      width="100%"
      height="44px"
    />
    <s-input
      v-else
      class="filters-bar__search"
      :value="searchTerm"
      :placeholder="searchPlaceholder"
      icon-left="search"
      size="large"
      @input="$emit('onSearch', $event)"
    />
    <FilterControl
      :level-filter="levelFilter"
      :sort-option="sortOption"
      :questionnaire="questionnaire"
      :is-filtered-by-order="isFilteredByOrder"
      @onLevelSelect="updateLevelFilter($event)"
      @onSortSelect="$emit('onSortSelect', $event)"
    />
  </div>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import FilterControl from './FilterControl'

export default {
  name: 'FiltersBar',
  components: {
    FilterControl,
  },
  mixins: [ mediaQueries ],
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
    levelFilter: {
      type: String,
      required: true,
    },
    sortOption: {
      type: Object,
      required: true,
      validator: (value) => value.field && value.direction,
    },
    questionnaire: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
    isFilteredByOrder: Boolean,
  },
  computed: {
    searchPlaceholder() {
      if (!this.mq_l) {
        return 'Digite parte do enunciado'
      }

      return 'Digite um termo'
    },
  },
  methods: {
    updateLevelFilter(level) {
      this.$emit('onLevelSelect', level)
    },
  },
}
</script>

<style lang="scss" scoped>
.filters-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $size-m;

  .sas-input {
    width: 45%;

    @include mq_s {
      width: 100%;
    }
  }

  &__search {
    @include mq_m {
      width: 319px;
    }
  }

  @include mq_s {
    display: flex;
    flex-direction: column;
    gap: $size-s;

    .sas-input {
      flex-grow: 1;
      margin-bottom: 0;
    }
  }

  ::v-deep .sas-input__wrapper {
    max-width: 450px;

    @include mq_m {
      max-width: 100%;
    }
  }

  ::v-deep .sas-input__field {
    background-color: $color-white;
    box-shadow: none;
  }

  ::v-deep .sas-button__text,
  ::v-deep .sas-button__icon,
  ::v-deep .adaptive-selector-dropdown__handle__caret {
    font-weight: 600;
    color: #707780;
    white-space: nowrap;
  }
}
</style>
