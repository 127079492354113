/* eslint-disable max-len */
const ptBR = {
  app: {
    title: 'Tarefa Online',
  },
  commons: {
    start: 'Iniciar',
    resume: 'Continuar',
    finished: 'Finalizado',
    seeReport: 'Ver relatório',
    back: 'Voltar',
    close: 'Fechar',
    delete: 'Excluir',
    tryAgain: 'Tentar novamente',
    explanation: 'Explicação',
    export: 'Exportar',
    report: 'Relatório',
    historic: 'Histórico',
    previous: 'Anterior',
    next: {
      female: 'Próxima',
      male: 'Próximo',
    },
    chapter: 'Capítulo {number}',
    moreDetails: 'Mais detalhes',
    lessDetails: 'Menos detalhes',
    details: 'Detalhes',
    yes: 'Sim',
    no: 'Não',
    ok: 'OK',
    skipIntro: 'Pular introdução',
    new: 'Novo',
    error: 'Erro',
    studentsCount: '{count} aluno | {count} alunos',
    studentsQuantity: '{count} aluno | {count} alunos',
    extraActivities: 'Atividades extras',
    extraActivitiesTabs: {
      byYear: 'Por ano',
      byDifficulty: 'Por dificuldade',
    },
    schoolYear: 'Ano letivo',
    classroom: 'Turma {name}',
  },
  lectures: {
    science: 'Ciências',
    math: 'Matemática',
    portuguese: 'Português',
    physics: 'Física',
    chemistry: 'Química',
    biology: 'Biologia',
  },
  shifts: {
    morning: 'Manhã',
    afternoon: 'Tarde',
    evening: 'Noite',
    fulltime: 'Integral',
  },
  levels: {
    easy: 'Fácil',
    medium: 'Médio',
    hard: 'Difícil',
    enigma: 'Enigma',
  },
  questions: {
    online: 'Você está online novamente. Agora você poderá responder a sua tarefa.',
    offline: 'Você está offline. Para continuar respondendo a tarefa, é preciso estar conectado à internet.',
    progress: 'Questão {current} de {total}',
    questionNumber: 'Questão {current}',
    empty: {
      error: {
        title: 'Ops, não conseguimos mostrar essa questão...',
        description: 'Ocorreu um problema ao tentar carregar a questão, por favor tente novamente.',
      },
      404: {
        title: 'Ops! Não existem questões disponíveis para essa atividade.',
        description: 'Que tal praticar outra disciplina?',
      },
    },
    actions: {
      answer: 'Responder',
      correct: 'Resposta correta',
      incorrect: 'Resposta incorreta',
      finish: 'Finalizar',
      review: 'Revisar capítulo',
      tips: 'Ver dica',
      tip: 'Dica',
      congratulation: 'Parabéns',
      ops: 'Não foi dessa vez... mas não desista',
    },
    reportAProblem: {
      buttonLabel: 'Reportar problema',
      title: 'Reportar problema com questão',
      subtitle: 'Qual o problema?',
      describeProblem: 'Descreva o problema',
      send: 'Enviar',
      problemReported: 'Problema reportado',
      youReportedAProblem: 'Você reportou um problema',
      youReported: 'Você reportou',
      studentsReportedAProblem: 'Foi reportado por seus alunos problema nas questões.',
      reasons: 'Motivos',
      complaintTypes: {
        all: 'Todos',
        orthographic_error: 'Erro ortográfico',
        list_of_answers_error: 'Gabarito incorreto',
        image_problems: 'Problema com imagem',
        others: 'Outros',
      },
      errorState: {
        title: 'Ocorreu um erro ao tentar reportar. Por favor tente novamente.',
      },
      successState: {
        title: 'O problema foi reportado. Obrigado pelo seu feedback!',
      },
    },
    drawer: {
      title: 'Vídeo aula',
      helper: 'Ainda precisa de ajuda?',
      getBook: 'Consultar livro',
      empty: {
        title: {
          review: 'Ops... não conseguimos mostrar esse conteúdo',
        },
        description: {
          review: 'Ocorreu um erro enquanto carregamos o vídeo do capítulo. Por favor, Tente novamente.',
        },
      },
      pageflip: {
        toast: {
          error: {
            message: 'Não conseguimos carregar seu livro. Por favor, tente novamente mais tarde.',
          },
        },
      },
    },
  },
  studentReport: {
    info: 'Seu desempenho em',
    back: 'Voltar para capítulos',
    redo: 'Refazer tarefa',
    assignmentHits: 'Acertos na tarefa',
    hitsCount: '{count} de {total} questão | {count} de {total} questões',
    rightAnswers: 'acerto | acertos',
    wrongAnswers: 'erro | erros',
    problemReported: 'Problema reportado',
    allQuestions: 'Todas as questões',
    confirm: {
      text: 'Tem certeza de que deseja refazer a tarefa?',
      ok: 'Refazer',
      cancel: 'Cancelar',
    },
    empty: {
      title: 'Ops, não conseguimos preparar seu relatório... ',
      description: 'Ocorreu um problema ao preparar seu relatório. Por favor, tente novamente.',
    },
  },
  share: {
    copy: 'Copiar',
    copied: 'Copiado!',
    share: 'Compartilhar',
    title: 'Compartilhar atividade',
    description: 'Copie o link abaixo e compartilhe com os alunos para responderem a atividade.',
  },
  survey: {
    rateYourExperience: 'Avalie a sua experiência',
  },
  assignments: {
    title: 'Atividades do livro e extras',
    subtitle: 'Acompanhe o desempenho das suas turmas e seus alunos através dos relatórios das atividades.',
    bookActivities: 'Atividades do livro',
    extraActivities: 'Atividades extras',
    createdByMyself: 'criada por mim',
    createdBy: 'criada por Prof. {name}',
    filterByLecture: 'Filtrar por disciplina',
    proposedActivities: {
      section: {
        title: 'Atividades de Reforço',
      },
      card: {
        title: 'Questões sugeridas',
      },
    },
  },
  classPicker: {
    selectClassroom: 'Selecionar turma',
    searchClassroom: 'Pesquisar turma',
    emptySearch: 'Nenhuma turma encontrada com "{searchString}"',
  },
  lecturePicker: {
    selectLecture: 'Selecionar disciplina',
  },
  report: {
    sortBy: 'Ordenar por',
    filterBy: 'Filtrar por',
    filter: 'Filtrar',
    createdBy: 'criado por Prof. {name}',
    createdByMyself: 'criado por mim',
    classroomStatistics: {
      emptyStudents: {
        title: 'Você não possui alunos nesta turma',
        description: 'Fale com o gestor da sua escola para cadastrar alunos nesta turma.',
      },
      emptyProposedAssignmentStudents: {
        title: 'Nenhum aluno desta turma finalizou a atividade',
        description: 'Aguarde os alunos que estão com dificuldade finalizarem alguma atividade de reforço para consultar os relatórios de desempenho.',
      },
      emptyQuestions: {
        title: 'Ops! Não existem questões disponíveis de {subject} para essa atividade.',
      },
      error: {
        title: 'Ocorreu um erro ao mostrar o relatório da turma',
        description: 'Por favor, tente novamente',
      },
    },
    tabs: {
      general: 'Visão geral',
      students: 'Alunos',
      questions: 'Desempenho por questão',
    },
    generalView: {
      empty: {
        title: 'Nenhum aluno desta turma iniciou a atividade',
        description: 'Compartilhe o link abaixo com os alunos para começarem a responder!',
      },
    },
    hitRate: {
      count: 'Total de {count} questão | Total de {count} questões',
    },
    engagement: {
      title: 'Engajamento',
      count: 'Total de {count} aluno | Total de {count} alunos',
      finished: 'Finalizaram',
      inProgress: 'Em Andamento',
      notStarted: 'Não iniciado',
    },
    difficulties: {
      title: 'Dificuldades da turma',
      subtitle: 'Questões com menores índices de acerto',
      question: 'Questão',
      hitRate: 'Índice de acertos',
      hitRateLong: 'Índice de acertos da questão',
      hits: '{count} de {total} aluno acertou | {count} de {total} alunos acertaram',
      hitsShort: '{count} de {total} aluno | {count} de {total} alunos',
      hitsShortest: 'acertos',
      viewAll: 'Ver todas as questões',
      export: {
        short: 'Exportar questões',
        long: 'Exportar questões em .docx',
        fileName: 'Questões - {grade} - {classroom} - {subtitle} - {title}.docx',
        error: 'Ocorreu um erro ao tentar exportar as questões.',
      },
      empty: {
        title: 'Nenhuma questão aqui ainda',
        description: 'É preciso que mais alunos finalizem a atividade para identitificar as dificuldades da turma.',
      },
    },
    students: {
      student: 'Aluno',
      pagination: '{from} - {to} de {total} aluno | {from} - {to} de {total} alunos',
      paginationQuestions: '{from} - {to} de {total} questões',
      hits: 'acertos',
      startedAt: 'Iniciou em',
      finishedAt: 'Finalizou em',
      at: 'em',
      filter: 'Filtrar alunos',
      statusLabel: 'Status',
      amountReinforcementLabel: 'quantidade de reforços',
      amountReinforcement: '{amount} reforço | {amount} reforços',
      sort: {
        smallerHitRate: 'Menor desempenho',
        greaterHitRate: 'Maior desempenho',
        recentFirst: 'Mais recentes primeiro',
        recentLast: 'Mais antigas primeiro',
      },
      search: 'Pesquisar por nome do aluno',
    },
    studentsFinished: {
      viewAll: 'Ver todos os alunos',
    },
    studentsView: {
      title: 'Desempenho dos alunos',
      studentsCount: '{count} aluno | {count} alunos',
    },
    studentDrawer: {
      assignmentHits: 'Acertos na tarefa',
      hitsCount: '{count} de {total} questão | {count} de {total} questões',
      redoCount: {
        start: 'Refez a tarefa',
        end: 'vez | vezes',
      },
      empty: {
        notStarted: {
          title: '{aluno} ainda não iniciou a atividade',
          description: 'Incentive {aluno} a iniciar a atividade para ver o seu desempenho e questões respondidas.',
        },
        notFinished: {
          title: '{aluno} ainda não finalizou a atividade',
          description: 'Assim que a atividade estiver finalizada, você terá acesso ao seu desempenho e as questões respondidas.',
        },
      },
    },
    questionsDrawer: {
      title: 'Questões',
      questionsCount: '{count} questão | {count} questões',
      questionNumber: 'Questão {number}',
      question: 'Questão',
      level: 'Dificuldade',
      content: 'Assunto',
      contents: 'Assuntos',
      skill: 'Habilidade BNCC',
      skillContent: 'Objeto de conhecimento',
      answer: 'Resposta',
      hit: 'Acertou',
      missed: 'Errou',
      seeQuestionProblemsDetails: 'Problema reportado na questão. <strong>Ver detalhes</strong>',
    },
    questionsView: {
      question: 'Questão',
      hitRate: 'Índice de acertos',
      hitRateLong: 'Índice de acertos da questão',
      hits: '{count} de {total} aluno acertou | {count} de {total} alunos acertaram',
      hitsShort: '{count} de {total} aluno | {count} de {total} alunos',
      questionsCount: '{count} questão | {count} questões',
      hitsShortest: 'acertos',
      level: 'Dificuldade',
      number: 'Nº',
      quantity: 'Quantidade',
      questionWithReportedProblem: 'Questão com problema reportado',
      export: {
        short: 'Exportar questões',
        long: 'Exportar questões em .docx',
        fileName: 'Questões - {grade} - {classroom} - {subtitle} - {title}.docx',
        error: 'Ocorreu um erro ao tentar exportar as questões.',
      },
    },
  },
  onBoarding: {
    1: {
      title: 'Bem-vindo(a) ao seu Relatório de Atividades',
      description: 'Seu relatório de atividades está repleto de novidades! Avance para saber mais. 😊',
      action: 'Avançar',
    },
    2: {
      title: 'Visão geral da turma no capítulo',
      description: 'Entenda o desempenho da turma com base na média de acertos e no engajamento.',
      action: 'Avançar',
    },
    3: {
      title: 'Dificuldades da turma',
      description: 'Analise as questões mais erradas pela turma e reforce em classe.',
      action: 'Avançar',
    },
    4: {
      title: 'Relatório individual do aluno',
      description: 'Além do desempenho da turma, você pode analisar o desempenho de cada aluno.',
      action: 'Avançar',
    },
    5: {
      title: 'Acesso a todas as questões respondidas',
      description: 'Agora você consegue visualizar todas as questões que o aluno respondeu.',
      action: 'Começar!',
    },
    wasItEasyToGetHere: 'Foi fácil chegar aqui?',
  },
  myActivities: {
    teacherTitle: 'Minhas atividades',
    managerTitle: 'Atividades da escola',
    buttonLabel: 'Criar atividade',
    teacherEmpty: {
      title: 'Nenhuma atividade criada',
      description: 'Você não criou atividades de <strong>{lecture}</strong> para esta turma. Clique em "{buttonLabel}" para começar!',
    },
    managerEmpty: {
      title: 'Nenhuma atividade criada',
      description: 'Nenhuma atividade de <strong>{lecture}</strong> foi criada para esta turma.',
    },
    redirect: {
      title: 'Indo para o Central de Questões',
      description: 'Você será levado para o Central de Questões SAS Plataforma de Educação para escolher suas questões e criar sua atividade!',
    },
    deleteConfirmation: {
      title: 'Quer mesmo excluir a atividade "<strong>{activity}</strong>"?',
      description: 'Ao excluir esta atividade os alunos da turma "<strong>{classroom}</strong>" não poderão mais responder e obter relatórios.',
      warning: 'Os alunos que já responderam a atividade perderão seus dados.',
      cancel: 'Cancelar',
      confirm: 'Excluir atividade',
      success: 'Atividade excluída com sucesso',
    },
  },
  studentsPerformance: {
    title: 'Atividades de reforço',
  },
  sasActivities: {
    empty: {
      title: 'Ainda não existem atividades do livro para “{lecture}”',
      description: 'Que tal criar uma atividade para sua turma?',
      buttonLabel: 'Criar atividade',
    },
  },
  pages: {
    error: {
      401: {
        title: 'Estamos com dificuldade em identificar o seu acesso.',
        type: 'Erro 401 - Não autorizado.',
        comment: 'Pode ter acontecido algo de errado durante o seu login. Vamos levá-lo de volta ao Portal SAS Plataforma de Educação para se autenticar novamente.',
        backButton: 'Voltar ao Portal SAS Educação',
      },
      403: {
        title: 'Você não tem permissão para visitar esta página.',
        type: 'Erro 403 - Proibido.',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar ao Portal SAS Educação',
      },
      404: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 404 - Página não encontrada',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar para o início',
      },
      500: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 500 - Erro interno do servidor.',
        comment: 'Encontramos uma instabilidade no sistema, mas vai ficar tudo bem. Volte mais tarde, que já estamos trabalhando nisso.',
        backButton: 'Voltar para o início',
      },
      502: {
        title: 'Nosso serviço está temporariamente indisponível.',
        type: 'Erro 502 - Bad gateway.',
        comment: 'Já estamos trabalhando para resolver o problema e não devemos demorar. Tente novamente mais tarde.',
        backButton: 'Voltar para o início',
      },
      503: {
        title: 'Nosso serviço está temporariamente indisponível.',
        type: 'Erro 503 - Serviço indisponível.',
        comment: 'Já estamos trabalhando para resolver o problema e não devemos demorar. Tente novamente mais tarde.',
        backButton: 'Voltar para o início',
      },
    },
  },
  adaptiveSelector: {
    emptyString: 'Nenhum resultado.',
  },
}

export default ptBR
/* eslint-enable max-len */
