import { render, staticRenderFns } from "./StudentsViewTable.vue?vue&type=template&id=7eff8f60&scoped=true"
import script from "./StudentsViewTable.vue?vue&type=script&lang=js"
export * from "./StudentsViewTable.vue?vue&type=script&lang=js"
import style0 from "./StudentsViewTable.vue?vue&type=style&index=0&id=7eff8f60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eff8f60",
  null
  
)

export default component.exports