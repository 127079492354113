<template>
  <div class="question-footer">
    <skeleton-loader
      v-if="loading"
      width="200px"
      height="20px"
    />
    <s-button
      v-else
      variation="tertiary"
      @click="$emit('close')"
    >
      Voltar
    </s-button>
    <template v-if="!hideNavigation">
      <div class="question-footer__controls">
        <div
          v-if="mq_m__mf"
          class="question-footer__controls__previous"
        >
          <s-button
            icon="arrow-left"
            variation="tertiary"
            :disabled="!hasPrevious"
            @click="$emit('previous')"
          />
        </div>
        <div
          v-if="mq_m__mf"
          class="question-footer__controls__next"
        >
          <s-button
            icon="arrow-right"
            variation="tertiary"
            :disabled="!hasNext"
            @click="$emit('next')"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'QuestionDrawerFooter',
  mixins: [ mediaQueries ],
  props: {
    question: {
      type: Object,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    hideNavigation: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.question-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $size-s $size-xl;
  border-top: 1px solid #E6E9ED;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: $color-white;

  &__controls {
    display: flex;
    gap: $size-s;

    &__previous, &__next {
      border: 1px solid #C9CED4;
      border-radius: 8px;
    }
  }
}
</style>
