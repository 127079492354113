<template>
  <div
    class="question-drawer-accordion"
    @click="toggle = !toggle"
  >
    <div class="question-drawer-accordion__header">
      <span class="question-drawer-accordion__title"> {{ title }} </span>
      <icon :type="toggle ? 'chevron-up' : 'chevron-down'" />
    </div>
    <div v-if="toggle">
      <p
        v-if="content"
        class="question-drawer-accordion__content"
      >
        {{ content }}
      </p>
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'QuestionDrawerAccordion',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      toggle: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.question-drawer-accordion {
  border: 1px solid #C9CED4;
  cursor: pointer;
  border-radius: 10px;
  padding: $size-s;
  background: #F7F9FA;
  margin: 0 $size-l;
  margin-bottom: $size-xs;
  font-family: "Inter";

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: $font-size-m;
    font-weight: 600;
  }

}
</style>
