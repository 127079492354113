var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sas-select',
    {
      'sas-select--active': _vm.active,
      'sas-select--disabled': _vm.disabled,
    },
  ]},[(_vm.label)?_c('label',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.label)}}):_vm._e(),_c('CustomDropdown',{class:['sas-select__dropdown', { 'sas-select--empty': !_vm.value }],attrs:{"disabled":_vm.disabled,"dropdown-class":`sas-select__dropdown-menu`,"icon":_vm.iconRight,"icon-left":_vm.iconLeft,"loading":_vm.loading,"open":_vm.active,"origin":"bottom-left","size":_vm.size,"text":_vm.getValue(_vm.value) || _vm.placeholder,"variation":_vm.variation},on:{"toggle":function($event){return _vm.toggle($event)}}},[(_vm.searchable)?_c('div',{staticClass:"sas-select__search"},[_c('Input',{attrs:{"icon-left":"search","placeholder":_vm.searchPlaceholder,"size":_vm.size,"width":"100%"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_vm._l((_vm.filteredOptions),function(option){return _c('CustomDropdownItem',{key:_vm.getValue(option),class:['sas-select__option', { 'sas-select__option--selected': _vm.isSelected(option) }],attrs:{"role":"option"},on:{"click":function($event){return _vm.select(option)}}},[_vm._v(" "+_vm._s(_vm.getValue(option))+" ")])}),(_vm.search && _vm.filteredOptions.length === 0)?_c('p',{staticClass:"sas-select__empty-search"},[_vm._v(" Nenhum resultado encontrado para "),_c('strong',{staticClass:"sas-select__empty-search_term"},[_vm._v(_vm._s(_vm.search))])]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }