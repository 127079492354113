<template>
  <div
    class="logos-report-skeleton"
    :elevation-hover="3"
  >
    <template>
      <div
        class="logos-report-skeleton__column-group"
      >
        <h1
          class="logos-report-skeleton__title"
        >
          <skeleton-loader
            width="200px"
            height="24px"
          />
        </h1>
      </div>

      <div class="logos-report-skeleton__group">
        <p class="logos-report-skeleton__text">
          <skeleton-loader
            width="100px"
            height="14px"
          />
        </p>
      </div>
      <skeleton-loader
        class="logos-report-skeleton__button"
        width="110px"
        height="36px"
      />

      <slot />
    </template>
  </div>
</template>

<script>
export default {
  name: 'LogosReportSkeleton',
}
</script>

<style lang="scss" scoped>
.logos-report-skeleton {
  @include flex-space-between;
  flex-wrap: wrap;
  padding: $size-s !important;
  margin-bottom: $size-s;
  border: 1px solid #E6E9ED;
  border-radius: 16px;

  @include mq-m--mf {
    flex-wrap: nowrap;
    padding: $size-s $size-m;
  }

  &__group {
    display: inline-flex;
    margin-bottom: $size-xs;

    @include mq-m--mf {
      margin-bottom: 0;
    }
  }

  &__column-group {
    display: inline-block;
    width: 100%;

    @include mq-m--mf {
      width: 35%;
    }
  }

  &__title {
    font-size: $font-size-heading-6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__button {
    color: #fff;
    box-shadow: 0 1px 3px -0.2px rgba(30,33,36,.25);
    font-size: .875rem;
    border-radius: 8px;
    padding: 0 12px;
  }

  &__text {
    white-space: nowrap;
    display: flex;
    color: $color-ink-light;
    font-size: $font-size-s;
    font-weight: $font-weight-semi-bold;
  }
}

</style>
