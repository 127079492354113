<template>
  <div class="student-report-header">
    <div class="student-report-header-top-bar">
      <SButton
        variation="secondary"
        @click="goBack()"
      >
        <Icon
          size="16"
          type="arrow-left"
        />
      </SButton>
      <p>Relatório do Estudante</p>
    </div>
    <header class="student-report-header__main">
      <div class="student-report-header-info">
        <h1 class="student-report-header__name">
          {{ studentName }}
        </h1>
        <div class="student-report-header__classroom">
          <skeleton-loader
            v-if="loading"
            width="100%"
            height="20px"
          />
          <span v-else>
            {{ title }}
          </span>
        </div>
      </div>

      <div class="student-report-header__main__controls">
        <div
          class="student-report-header__main__controls__previous"
        >
          <s-button
            icon="arrow-left"
            variation="secondary"
            :disabled="!hasPrevious"
            @click="$emit('previous-student')"
          />
        </div>
        <div
          class="student-report-header__main__controls__next"
        >
          <s-button
            icon="arrow-right"
            variation="secondary"
            :disabled="!hasNext"
            @click="$emit('next-student')"
          />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SButton, Icon } from '@sas-te/alfabeto-vue'

import { getStatusVariation } from 'App/utils/status'
import formatDate from 'App/mixins/formatDate'
import mediaQueries from 'shared/mixins/mediaQueries'
import { isBookActivity } from './enums/questionnaireTypeEnum'

export default {
  name: 'StudentDrawerHeader',
  components: {
    SButton,
    Icon,
  },
  mixins: [ formatDate, mediaQueries ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    classroom: {
      type: Object,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
    ]),
    title() {
      if (!this.classroom) return ''
      const shift = this.classroom?.shift
      const capitalizedShift = shift?.charAt(0).toUpperCase() + shift?.slice(1)

      return `${this.classroom?.grade.name} • ${this.classroom?.name} • ${capitalizedShift}`
    },
    studentName() {
      return this.student?.name
    },
    isShowAssignmentSelector() {
      return this.isShowSessions && this.isBookActivityMission
    },
    isBookActivityMission() {
      return isBookActivity(this.questionnaire.data?.type)
    },
  },
  methods: {
    getStatusVariation,
    goBack() {
      this.$router.push({
        name: 'mfe-extra-activity-report-general',
        params: {
          questionnaireCode: this.questionnaire?.data.code,
          classroomId: this.classroom?.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.student-report-header {
  font-family: "Inter";
  background: $color-white;

  .student-report-header-top-bar {
    padding: $size-s;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #E6E9ED;

    p {
      font-size: 18px;
      font-weight: 600;
      line-height: 22.5px;
      color: #191C1F;
    }

    ::v-deep .sas-button.--secondary {
      border: none;
      background: none;
      box-shadow: none;
    }
}

  &__main {
    background: $color-white;
    display: flex;
    justify-content: space-between;
    padding: 3.5rem 5rem;

    @include mq_s {
      flex-direction: column;
    }

    &__id {
      padding: $size-s;

      @include mq-s--mf {
        padding: $size-l;
      }

      &__photo {
        margin-bottom: $size-s;
      }

      &__name {
        margin-bottom: $size-xxs;
      }

      &__classroom {
        color: $color-ink-light;
      }
    }

    &__controls {
      margin: $size-xs;
      display: flex;
      align-items: center;

      @include mq_s {
        margin: 0;
        margin-top: 2rem;
      }

      &__previous {
        padding-right: $size-xs;
        button {
          padding: $size-m !important;
          box-shadow: none;
        }
      }

      &__next {
        padding-left: $size-xs;
        padding-right: $size-xs;
        button {
          padding: $size-m !important;
          box-shadow: none;
        }
      }

      &__close {
        padding-left: $size-xs;
      }
    }
  }

  &__name {
    font-family: "Inter";
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    color: #191C1F;
  }

  &__classroom {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #707780;
  }

  &__extra {
    padding: $size-s;
    display: flex;
    flex-wrap: wrap;

    @include mq-s--mf {
      padding: $size-m $size-l;
    }

    @include mq-m--mf {
      flex-wrap: nowrap;
    }

    &__details {
      flex-grow: 1;
      @include flex-center-start;
      flex-wrap: wrap;

      &__lecture {
        color: $color-ink-light;
        margin-right: $size-s;
      }

      &__chapter {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        margin: $size-xs 0 $size-s;

        h5 {
          margin-right: $size-xs;
        }
      }
    }

    &__time-details {
      flex-shrink: 1;
      white-space: nowrap;

      @include mq-m--mf {
        text-align: right;
      }

      &__started-at,
      &__finished-at {
        color: $color-ink-light;
        display: block;
      }

      &__started-at {
        margin-bottom: $size-xs;
      }

      ::v-deep .sas-skeleton-loader {
        display: inline-block;
      }
    }
  }

}
</style>
