<template>
  <div class="student-details">
    <StudentDrawerHeader
      v-if="!students.loading"
      :student="selectedStudent.student"
      :loading="students.loading || questionnaire.loading || classroom.loading"
      :questionnaire="questionnaire"
      :classroom="classroom"
      :has-next="hasNext"
      :has-previous="hasPrevious"
      @previous-student="previousStudent"
      @next-student="nextStudent"
    />
    <div
      v-if="selectedStudent && selectedStudent.assignments"
      class="student-details__body"
    >
      <div class="student-details__charts">
        <div class="student-details__card">
          <div class="student-details__card-title student-details__card-activity">
            <h4>Atividade</h4>
            <div>
              <skeleton-loader
                v-if="questionnaire.loading"
                class="student-details__card-lecture"
                width="430px"
                height="24px"
              />
              <p
                v-else
                class="student-details__card-lecture"
              >
                {{ lectureTitle }}
              </p>
              <skeleton-loader
                v-if="questionnaire.loading"
                class="student-details__card-lecture"
                width="430px"
                height="24px"
              />
              <p
                v-else
                class="student-details__card-subtitle"
              >
                {{ subtitle }}
              </p>
              <p
                :style="getAssignmentStatusStyle"
                class="student-details__card-status"
              >
                {{ assignmentStatus }}
              </p>
            </div>
          </div>
        </div>

        <div class="student-details__card">
          <div class="student-details__card-title">
            <h4> Percentual de acerto </h4>
            <div class="student-details__card__progress__chart">
              <h2>{{ hitRate }}%</h2>
              <ApexCharts
                class="student-details__card__progress__chart__apexchart"
                type="bar"
                :options="chartOptions"
                :series="chartSeries"
                height="50px"
                width="100%"
              />
              <p class="student-details__card__progress__chart__label">
                {{ correctAnswers }} de {{ totalQuestions }} acertos na atividade
              </p>
            </div>
          </div>
        </div>
      </div>
      <template v-if="mq_l">
        <template v-if="answeredQuestions.loading">
          <StudentDetailsQuestionsTableSkeleton />
        </template>
        <template v-else-if="!answeredQuestions.error">
          <StudentDetailsQuestionsCards
            :questions="questions"
            @selectQuestion="selectQuestion"
          />
        </template>
      </template>
      <template v-else>
        <div class="student-details__table">
          <div class="student-details__table__header">
            <h4>Desempenho por questão</h4>
            <span
              v-if="!assignmentCodeInitialOrQueryParam"
              class="student-details__table__not-started"
            >Atividade não iniciada.</span>
            <span
              v-else
              class="student-details__table__questions-count"
            >{{ questionsCount }}</span>
          </div>
          <StudentDetailsQuestionsTable
            v-if="questionsCount"
            ref="questionsTable"
            :questions="questions"
            :active-question="activeQuestion"
            @selectQuestion="selectQuestion"
          />
        </div>
      </template>
      <QuestionDrawer
        v-if="activeQuestion"
        :disabled-report="reportIsFromMagnaMission"
        :question="activeQuestion"
        :is-teacher-challenge="isTeacherChallenge"
        :assignment-code="assignmentCodeInitialOrQueryParam"
        :overlay-enabled="false"
        :has-previous="hasPreviousQuestion"
        :has-next="hasNextQuestion"
        :hide-report="hideMoreOptions"
        @update-reports="fetchQuestions"
        @on-close="clearSelectedQuestion"
        @on-previous="previousQuestion"
        @on-next="nextQuestion"
      />
    </div>
  </div>
</template>

<script>
import { studentAssignmentStatus } from 'MFE/utils/status'
import ApexCharts from 'vue-apexcharts'
import questions from '@/service/questions'
import statisticsApi from '@/service/statistics'
import mediaQueries from 'shared/mixins/mediaQueries'
import simplur from 'simplur'
import keyEvents from 'App/mixins/keyEvents'
import QuestionDrawer from 'MFE/components/QuestionDrawer/QuestionDrawer'
import { orderBy, isEmpty } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import StudentDrawerHeader from './StudentDrawerHeader'
import StudentDetailsQuestionsCards from './StudentDetailsQuestionsCards'
import StudentDetailsQuestionsTable from './StudentDetailsQuestionsTable'
import StudentDetailsQuestionsTableSkeleton from './StudentDetailsQuestionsTableSkeleton'

export default {
  name: 'StudentDetails',
  components: {
    StudentDrawerHeader,
    ApexCharts,
    StudentDetailsQuestionsCards,
    StudentDetailsQuestionsTable,
    StudentDetailsQuestionsTableSkeleton,
    QuestionDrawer,
  },
  mixins: [ mediaQueries, keyEvents ],
  data() {
    return {
      selectedStudent: {
        data: {},
      },
      students: [],
      questionnaire: {
        data: {},
        loading: false,
        error: null,
      },
      classroom: {
        data: {},
        loading: false,
        error: null,
      },
      answeredQuestions: {
        data: {},
        loading: false,
        error: null,
      },
      activeQuestionInnerIndex: -1,
    }
  },
  computed: {
    ...mapState([
      'yearSelectedContent',
    ]),
    ...mapGetters([
      'classrooms',
    ]),
    lectureTitle() {
      return this.questionnaire?.data.title
    },
    subtitle() {
      return this.classroom?.name
    },
    grade() {
      return this.classroom?.grade?.name
    },
    correctAnswers() {
      if (!this.selectedStudent || !this.selectedStudent.assignments) {
        return '-'
      }
      const assignment = this.selectedStudent?.assignments.find(
        (item) => item.code === this.assignmentCodeInitialOrQueryParam
      )

      return assignment?.correctAnswers ?? '0'
    },
    totalQuestions() {
      return this.selectedStudent?.questionsCount ?? '-'
    },
    chartSeries() {
      return [
        {
          name: 'Acertos',
          data: [ this.hitRate ],
        },
      ]
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            barHeight: '10px',
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: [ '#54C985' ],
          opacity: 1,
        },
        xaxis: {
          max: 100,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          show: false,
        },
        yaxis: {
          show: false,
        },

        grid: {
          show: false,
          borderColor: '#fff',
          row: {
            colors: [ '#F7F9FA' ],
          },
        },
        tooltip: {
          enabled: false,
        },
      }
    },
    assignmentCodeInitialOrQueryParam() {
      if (this.$route.query?.assignmentCode) {
        return this.$route.query?.assignmentCode
      }

      return this.selectedStudent?.assignmentCode ?? null
    },
    activeStudent() {
      return this.$route.query?.activeStudent ?? null
    },
    assignments() {
      if (isEmpty(this.selectedStudent.assignments)) {
        return []
      }

      return orderBy(this.selectedStudent.assignments, [ 'retryCount' ], [ 'asc' ])
    },
    assignmentStatus() {
      if (!this.selectedStudent || !this.selectedStudent.assignments) {
        return '-'
      }
      const assignment = this.assignments.find(
        (item) => item.code === this.assignmentCodeInitialOrQueryParam
      )

      if (!assignment) {
        return ` • ${studentAssignmentStatus[this.assignments[0]?.status]}` ?? '-'
      }

      return ` • ${studentAssignmentStatus[assignment?.status]}` ?? '-'
    },
    getAssignmentStatusStyle() {
      const assignment = this.assignments.find(
        (item) => item.code === this.assignmentCodeInitialOrQueryParam
      )

      return {
        color: (() => {
          if (assignment?.status === 'FINISHED') {
            return '#00702F'
          } if (assignment?.status === 'INCOMPLETE') {
            return '#8F5100'
          }

          return '#707780'
        })(),
      }
    },
    hasAnswers() {
      return !this.answeredQuestions.error
        && Array.isArray(this.answeredQuestions.data)
        && this.answeredQuestions.data.length > 0
    },
    questions() {
      if (!this.answeredQuestions.loading && this.hasAnswers) {
        return this.answeredQuestions.data.map((question, index) => ({
          ...question,
          number: index + 1,
        }))
      }

      return []
    },
    questionsCount() {
      return !this.answeredQuestions.loading
        && this.hasAnswers
        ? simplur`${this.answeredQuestions.data.length} [questão|questões]`
        : null
    },
    correctsCount() {
      return !this.answeredQuestions.loading
        && this.hasAnswers
        ? this.answeredQuestions.data.filter(
          (question) => question.correct
        ).length
        : 0
    },
    hitRate() {
      if (!isEmpty(this.assignmentCodeInitialOrQueryParam)) {
        const hitRate = this.assignments.find((assignment) => (
          assignment.code === this.assignmentCodeInitialOrQueryParam
        ))?.hitRate ?? null

        return Math.round(hitRate)
      }

      return Math.round(this.selectedStudent.student.hitRate ?? null)
    },
    activeQuestion() {
      const { activeQuestion } = this.$route.query
      if (!activeQuestion) return null

      const [ question ] = this.questions.filter((item) => (
        item.id === activeQuestion
      ))

      return question
    },
    activeQuestionIndex() {
      return this.questions.findIndex((question) => (
        question.id === this.activeQuestion.id
      ))
    },
    hasPreviousQuestion() {
      return this.activeQuestionInnerIndex > 0
    },
    hasNextQuestion() {
      return this.activeQuestionInnerIndex < this.questions?.length - 1
    },
    currentStudentIndex() {
      const studentId = this.selectedStudent.student?.id

      return this.students.findIndex((student) => student.student.id === studentId)
    },
    hasNext() {
      return this.currentStudentIndex < this.students.length - 1
    },
    hasPrevious() {
      return this.currentStudentIndex > 0
    },
    classroomId() {
      return this.$route.params.classroomId
        || this.$route.query.classroomId
    },
    isTeacherChallenge() {
      return this.questionnaire.data?.isTeacherChallenge || false
    },
    isChallengeActivity() {
      return this.questionnaire.data?.isChallengeActivity || false
    },
    reportIsFromMagnaMission() {
      return this.isTeacherChallenge || this.isChallengeActivity
    },
  },
  created() {
    document.addEventListener('keyup', this.pressEsc, false)
    this.startFetchs()
  },
  destroyed() {
    document.removeEventListener('keyup', this.pressEsc, false)
    this.clearAnsweredQuestions()
  },
  methods: {
    ...mapActions([ 'getClassrooms' ]),
    async startFetchs() {
      Promise.all([
        this.fetchStudentsStatistics(),
        this.fetchQuestionnaire(),
        this.fetchClassroom(),
        this.fetchQuestions(),
      ])
    },
    async fetchStudentsStatistics() {
      const { classroomId, questionnaireCode, studentId } = this.$route.query

      this.students.loading = true

      try {
        const response = await statisticsApi.getStudentsStatistics({
          classroomId,
          questionnaireCode,
          contentYear: this.yearSelectedContent,
        })

        this.students = response.data
        this.selectedStudent = this.students.find(
          (student) => student.student.id === Number(studentId)
        )
      } catch (error) {
        this.students.error = true
      } finally {
        this.students.loading = false
      }
    },
    async fetchQuestions() {
      await this.fetchStudentsStatistics()

      this.answeredQuestions.loading = true

      await this.getAnsweredQuestion()

      this.answeredQuestions.loading = false
    },
    async fetchQuestionnaire() {
      const { questionnaireCode } = this.$route.query

      this.questionnaire.loading = true
      this.questionnaire.data = null
      this.questionnaire.error = false

      try {
        const response = await statisticsApi.getQuestionnaire(questionnaireCode)
        this.questionnaire.data = response
      } catch (error) {
        this.questionnaire.error = true
      } finally {
        this.questionnaire.loading = false
      }
    },
    async fetchClassroom() {
      const { classroomId } = this.$route.query

      if (!classroomId) {
        return
      }

      this.classroom.loading = true

      try {
        const contentYear = this.yearSelectedContent ?? new Date().getFullYear()
        await this.getClassrooms(contentYear)

        this.classroom = this.classrooms?.data.find((classroom) => (
          classroom.id === Number(classroomId)
        ))
      } catch (error) {
        this.classroom.error = true
      } finally {
        this.classroom.loading = false
      }
    },
    nextStudent() {
      if (this.hasNext) {
        const nextStudent = this.students[this.currentStudentIndex + 1]
        this.selectedStudent = nextStudent
        this.updateStudent(nextStudent)
      }
    },
    previousStudent() {
      if (this.hasPrevious) {
        const previousStudent = this.students[this.currentStudentIndex - 1]
        this.selectedStudent = previousStudent
        this.updateStudent(previousStudent)
      }
    },
    async updateStudent(student) {
      this.selectedStudent = student
      const studentId = student?.student.id
      this.$router.replace({
        query: {
          ...this.$route.query,
          studentId,
          assignmentCode: this.selectedStudent?.assignments[0]?.code,
        },
      })
      await this.getAnsweredUserQuestion(studentId)
    },
    clearAnsweredQuestions() {
      this.answeredQuestions = {
        data: {},
        loading: false,
        error: null,
      }
    },
    async getAnsweredUserQuestion(beforeRequestStudentId) {
      const { classroomId } = this.$route.query

      if (!classroomId) {
        return
      }

      try {
        const answeredQuestions = await questions
          .fetchAnsweredUserQuestions(this.assignmentCodeInitialOrQueryParam, this.classroomId)

        if (this.student.id === beforeRequestStudentId) {
          this.answeredQuestions.data = answeredQuestions
        }
      } catch (error) {
        this.answeredQuestions.error = error.response
      }
    },
    async getAnsweredQuestion() {
      if (this.assignmentCodeInitialOrQueryParam && this.classroomId) {
        try {
          const answeredQuestions = await questions
            .fetchAnsweredQuestions(this.assignmentCodeInitialOrQueryParam, this.classroomId)

          this.answeredQuestions.data = answeredQuestions
        } catch (error) {
          this.answeredQuestions.error = error.response
        }
      }
    },
    selectQuestion(event) {
      const question = event.data
      const { activeQuestion } = this.$route.query
      if (question.id !== activeQuestion) {
        this.activeQuestionInnerIndex = event.index
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeQuestion: question.id,
          },
        })
      }
    },
    clearSelectedQuestion() {
      const query = { ...this.$route.query }
      delete query.activeQuestion
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex > 0) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex - 1],
            index: this.activeQuestionIndex - 1,
          })
        }
      } else {
        this.$refs.questionsTable.previousQuestion()
      }
    },
    nextQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex < this.filteredQuestions.length - 1) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex + 1],
            index: this.activeQuestionIndex + 1,
          })
        }
      } else {
        this.$refs.questionsTable.nextQuestion()
      }
    },
    pressEsc(event) {
      if (this.isEscKeyPressed(event) && !this.$route.query.activeQuestion) {
        this.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.student-details {
  background-color: #F7F9FA;
  width: 100%;

  &__charts {
    display: flex;
    justify-content: space-between;
    gap: $size-m;
    margin-bottom: 2rem;

    @include mq_s {
      flex-direction: column;
    }
  }

  &__card {
    padding: 2rem;
    background-color: $color-white;
    width: 100%;
    min-height: 192px;
    border-radius: 16px;
    border: 1px solid #E6E9ED;

    &-title h4 {
      margin-bottom: 1rem;
    }

    &__progress__chart {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      &__label {
        font-family: "Inter";
        align-self: flex-start;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        color: #707780;
      }

      &__apexchart {
        width: 110%;
      }
    }

    &-lecture, &-subtitle {
      margin-bottom: 8px;
      font-family: "Inter";
      font-weight: 400;
    }

    &-lecture {
      font-size: 16px;
      line-height: 24px;
      color: #191C1F;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 17.5px;
      color: #707780;
    }

    &-activity {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &-status {
      font-weight: 600;
    }
  }

  &__body {
    padding-bottom: $end-space;
    max-width: 90%;
    margin: 0 auto;
    padding: 32px 0;

    @include mq_s {
      max-width: 91.5%;
    }
  }

  &__table {
    margin-top: 2rem;
    padding: 24px;
    background: $color-white;
    border-radius: 16px;
    border: 1px solid #E6E9ED;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 17.5px;
      color: #707780;
    }
  }
}
</style>
