<template>
  <div class="essential-learning-table">
    <h3 class="essential-learning-table__title">
      Desempenho por aprendizagens essenciais
    </h3>
    <span class="essential-learning-table__subtitle">
      A média de acertos é baseada na média dos resultados dos estudantes na fase diagnóstica
    </span>
    <skeleton-loader
      v-if="loading"
      width="1032px"
      height="340px"
    />
    <STable
      v-else-if="mq_l__mf"
      ref="essentialLearningEvaluatedTable"
      :content="essentialLearningList"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="true"
      :items-per-page="5"
      @sorted="tableSorted"
    >
      <template
        slot="title"
        slot-scope="{ row }"
      >
        <div class="essential-learning-table__name">
          <span>
            {{ row.title }}
          </span>
        </div>
      </template>

      <template
        slot="learningPercentage"
        slot-scope="{ row }"
      >
        <p class="essential-learning-table__hit-rate">
          <b>{{ row.learningPercentage }}%</b>
        </p>
      </template>
    </STable>
    <template v-if="mq_m">
      <EssentialLearningEvaluatedCard
        v-for="(topic, index) in essentialLearningList"
        :key="index"
        :topic="topic"
        @click.native="selectQuestion(question, index)"
      />
    </template>
  </div>
</template>

<script>
import STable from 'MFE/components/STable'
import mediaQueries from 'shared/mixins/mediaQueries'
import EssentialLearningEvaluatedCard from './EssentialLearningEvaluatedCard'

export default {
  name: 'EssentialLearningEvaluatedTable',
  components: {
    STable,
    EssentialLearningEvaluatedCard,
  },
  mixins: [ mediaQueries ],
  props: {
    essentialLearningList: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultSort: {
        field: 'title',
        direction: 'asc',
      },
      fields: [
        {
          value: 'title',
          text: 'Aprendizagem essencial',
          sortable: true,
        },
        {
          value: 'learningPercentage',
          text: 'Média de acertos',
          sortable: true,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ],
    }
  },
  computed: {
    flatEssentialLearningList() {
      return this.essentialLearningList.map((essentialLearning) => ({
        ...essentialLearning,
        learningPercentage: `${essentialLearning.learningPercentage}%`,
      }))
    },
  },
  created() {
    if (this.$route.query.sort) {
      const [ field, direction ] = this.$route.query.sort.split(',')
      this.defaultSort = {
        field,
        direction,
      }
    }
  },
  methods: {
    tableSorted(sort) {
      this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          sort: `${sort.field},${sort.direction}`,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.essential-learning-table {
  margin-top: $size-xl;

  ::v-deep .sas-table tr:hover {
    background-color: #E6E9ED;
  }

  &__name {
    min-width: 250px;
  }

  &__hit-rate {
    float: right;
    padding-right: $size-m;
  }

  &__title {
    color: $color-ink;
    display: flex;
    align-items: center;
    font-family: "Inter";
  }

  ::v-deep  &__info-icon {
    color: $color-ink-light;
    margin-left: $size-xs;
  }

  &__subtitle {
    display: block;
    font-family: "Inter";
    color: $color-ink-light;
    margin-bottom: $size-l;
  }

  &__name span {
    font-weight: $font-weight-base;
    font-size: $font-size-m;
    font-family: "Inter";
    color: #191C1F;
  }

  ::v-deep .sas-badge__text {
    white-space: nowrap;
  }

  ::v-deep .sas-badge i {
    margin-right: $size-xxs;
  }

  ::v-deep .sas-table__header {
    height: $size-xl;
  }

  ::v-deep .sas-badge--neutral {
    background: $color-ink-lightest;
    color: $color-ink;
    border-radius: 100px;
    border: 1px solid $color-ink;
    opacity: 0.75;
  }
}
</style>
