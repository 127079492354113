<template>
  <div class="profile-picture">
    <img
      v-if="user.profilePicture"
      class="picture"
      alt="user profile picture"
      :src="user.profilePicture"
    >
    <div
      v-else
      class="initials"
    >
      {{ initials }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfilePicture',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    initials() {
      const { name } = this.user

      if (name.length) {
        return name.trim()[0]
      }

      return ''
    },
  },
}
</script>

<style lang="sass" scoped>
.profile-picture
  height: 32px
  min-width: 32px
  background: rgba($color-ink-lightest, 0.5)
  border-radius: 50%
  +flex-center

  .picture
    border-radius: 50%
    object-fit: cover
    height: 100%
    width: 100%

  .initials
    font-size: $font-size-s
    text-transform: uppercase
    font-weight: $font-weight-semi-bold
    color: $color-ink-light
</style>
