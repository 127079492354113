<template>
  <div
    v-if="!loading"
    class="recomposition-progression-path__performance-card"
  >
    <h4 class="recomposition-progression-path__performance-card__title">
      Participação
    </h4>

    <ApexCharts
      v-if="!loading && chartSeries.length"
      type="donut"
      :options="chartOptions"
      :series="chartSeries"
      height="100%"
      width="100%"
    />
  </div>
</template>

<script>
import simplur from 'simplur'
import ApexCharts from 'vue-apexcharts'

import mediaQueries from 'shared/mixins/mediaQueries'
import accentFold from 'App/mixins/accentFold'

export default {
  name: 'RecompositionProgressionPathTestPerformance',
  components: {
    ApexCharts,
  },
  mixins: [ mediaQueries, accentFold ],
  props: {
    studentsTotalAmount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    performanceRange: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.chartLabels,
        dataLabels: {
          enabled: false,
        },
        colors: [ '#54C985', '#F47B7B', '#2E93FA', '#E8E8E8' ],
        legend: {
          show: true,
          horizontalAlign: 'left',
          position: 'right',
          fontSize: '14px',
          fontFamily: 'Inter',
          markers: {
            customHTML() {
              return '<div style="width: 20px; height: 20px; background: currentColor; border-radius: 4px;"></div>'
            },
          },
          formatter: (seriesName, opts) => {
            const amount = this.performanceRange[opts.seriesIndex]?.amount || 0
            const percentage = Math.round((amount / this.studentsTotalAmount) * 100)
            if (typeof percentage !== 'number') {
              return seriesName
            }

            return `
              <div style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 0.5rem;">
                <div>
                  <span style="font-weight: bold; font-size: 16px;">${amount}</span>
                  <span style="font-size: 14px; color: #707780;">(${percentage}%)</span>
                </div>
                <span>${seriesName}</span>
              </div>
            `
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              chart: {
                height: 350,
                width: 350,
              },
              plotOptions: {
                pie: {
                  horizontal: false,
                  donut: {
                    size: '65%',
                  },
                },
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      }
    },
    chartSeries() {
      return this.performanceRange.map((item) => item.amount)
    },
    chartLabels() {
      const label = this.performanceRange.map(({ text }) => text)

      return label.map((item) => `${item}`)
    },
    studentsAmountDescription() {
      if (this.studentsTotalAmount <= 0 || !this.loading) {
        return ''
      }

      return simplur`Total de ${this.studentsTotalAmount} [aluno|alunos]`
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.recomposition-progression-path__performance-card {
  flex-direction: column;
  border: 1px solid #E6E9ED;
  border-radius: 16px;
  padding: $size-m;
  width: 736px;
  flex: auto;

  @include mq_l {
    width: 704px;
  }

  @include mq_s {
    width: 24em;
    height: 30em;
  }

  ::v-deep .apexcharts-legend-marker {
    border-radius: 0 !important; /* Force square markers */
    width: 20px !important; /* Adjust size if necessary */
    height: 20px !important;
  }

  ::v-deep .apexcharts-legend-text {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  ::v-deep .apexcharts-legend.apx-legend-position-right {
    margin-top: 24px;
  }

  ::v-deep .apexcharts-legend {
    @include mq_s {
      display: flex;
      flex-direction: column;

      max-width: 100%; /* Garante que a legenda respeite a largura do card */
      overflow-wrap: break-word; /* Permite que palavras longas sejam quebradas */
      word-wrap: break-word;
      word-break: break-word;
      display: flex;
      flex-wrap: wrap; /* Quebra as linhas caso necessário */
      justify-content: center; /* Centraliza a legenda no card */

    }
  }

  ::v-deep .apexcharts-datalabel-label {
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    line-height: 17.5px;
  }

  ::v-deep .apexcharts-datalabel-value {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
  }

  &__title {
    flex: 1 0 auto;
    font-family: "Inter", sans-serif;
    margin-bottom: $size-m;
    font-weight: 600;
  }

  &__chart {
    flex: 1 0 100%;
    height: $size-m;
    margin: $size-s 0;
    border-radius: $size-xxs;
    @include flex-center;
  }

  &__legend {
    @include mq-media--mf(1024px) {
      @include flex-center;
    }

    &__item {
      margin-top: $size-xs;
      @include flex-center-start;

      @include mq-media--mf(1024px) {
        margin-top: 0;
        @include space-inline($size-s);
      }

      @include mq-media--mf(1080px) {
        @include space-inline($size-l);
      }

      &__bullet {
        width: $size-s;
        height: $size-s;
        display: inline-block;
        border-radius: 50%;
        margin-right: $size-xs;
      }

      &__label {
        margin-right: $size-xxs;
      }
    }
  }
}
</style>
