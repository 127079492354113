<template>
  <div class="questions-view-table">
    <s-table
      ref="sTable"
      :fields="fields"
      :content="questions"
      :selected-row="activeQuestion"
      :default-sort="defaultSort"
      :loading="loading"
      :paginate="true"
      :skeleton-lines="5"
      @click-row="clickRow"
    >
      <template #number="{ row }">
        <skeleton-loader
          v-if="loading"
          width="24px"
          height="24px"
        />
        <div
          v-else
          class="questions-view-table__number"
        >
          <span>
            {{ row.order }}
          </span>
        </div>
      </template>
      <template #statement="{ row }">
        <div
          v-if="!loading"
          class="questions-view-table__question"
        >
          <span
            v-if="mustShowSubject"
            class="questions-view-table__question__content"
          >
            {{ row.content.name }}
          </span>
          <h6>
            {{ truncateText(row.statement, statementMaxLength) }}
          </h6>
        </div>
        <template v-else>
          <skeleton-loader
            class="questions-view-table__question__content"
            width="20%"
            height="19px"
          />
          <skeleton-loader
            width="270px"
            height="25px"
          />
        </template>
      </template>
      <template #level="{ row }">
        <div
          class="questions-view-table__level"
        >
          {{ $t(`levels.${row.level}`) }}
        </div>
      </template>
      <template #hitRate="{ row }">
        <template v-if="!loading">
          <p class="questions-view-table__hit-rate">
            <b>{{ row.hitRate | handleHitRate }}</b>
          </p>
        </template>
        <template v-else>
          <div class="questions-view-table__hit-rate">
            <skeleton-loader
              width="34px"
              height="22px"
            />
          </div>
          <div class="questions-view-table__hit-count">
            <skeleton-loader
              width="100%"
              height="19px"
            />
          </div>
        </template>
      </template>
    </s-table>
  </div>
</template>

<script>
import truncateText from 'shared/utils/truncateText'
import STable from 'MFE/components/STable'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import { isNaN, isNil } from 'lodash'

export default {
  name: 'QuestionsTable',
  components: { STable },
  filters: {
    handleHitRate: (hitRate) => {
      if (isNaN(hitRate) || isNil(hitRate)) return '-'

      return `${hitRate}%`
    },
  },
  mixins: [ subjectVisibilityToggle ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    activeQuestion: {
      type: Object,
      default: null,
      required: false,
    },
    levelFilter: {
      type: String,
      default: 'all',
    },
    isClassroomActivity: Boolean,
  },
  computed: {
    isAncestralArenaActivity() {
      return this.$route.name === 'mfe-extra-activity-report-questions'
    },
    statementMaxLength() {
      switch (this.$mq) {
        case 'large':
          return 80
        case 'x_large':
          return 100
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
    defaultSort() {
      const defaultSort = {}

      if (!this.isClassroomActivity) {
        defaultSort.field = 'number'
        defaultSort.direction = 'asc'
      }

      return defaultSort
    },
    filteredQuestions() {
      if (!this.levelFilter || this.levelFilter === 'all') {
        return this.questions
      }

      return this.questions.filter((question) => question.level === this.levelFilter)
    },
    sortedQuestions() {
      const { field, direction } = this.sortOption

      return [ ...this.questions ].sort((a, b) => {
        if (direction === 'asc') return a[field] - b[field]
        if (direction === 'desc') return b[field] - a[field]

        return 0
      })
    },
    fields() {
      const fields = [
        {
          value: 'statement',
          text: 'Enunciado',
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'level',
          text: this.$t('report.questionsView.level'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => {
            if (a === b) return 0
            if (a === 'easy') return -1 * modifier
            if (a === 'hard') return modifier
            if (a === 'medium' && b === 'easy') return modifier

            return -1 * modifier
          },
        },
        {
          value: 'hitRate',
          text: 'Percentual de acerto',
          sortable: true,
          width: '200px',
        },
      ]

      if (!this.isAncestralArenaActivity) {
        fields.splice(1, 1) // Remove 'levels' column
      }

      if (!this.isClassroomActivity) {
        fields.unshift({ value: 'number', text: 'nº', sortable: true })
      }

      return fields
    },
  },
  methods: {
    truncateText,
    clickRow({ data, dataIndex }) {
      this.$emit('selectQuestion', {
        question: data,
        index: dataIndex,
      })
    },
    previousQuestion() {
      this.$refs.sTable.previous()
    },
    nextQuestion() {
      this.$refs.sTable.next()
    },
  },
}
</script>

<style lang="scss" scoped>
.questions-view-table {
  font-family: "Inter";

  ::v-deep .sas-table tr:hover {
    background-color: #E6E9ED;
  }

  ::v-deep .sas-table__header__field {
    color: #707780;
  }

  h6 {
    font-family: "Inter";
    font-size: $font-size-m;
    font-weight: 400;
    line-height: 24px;
  }

  ::v-deep .sas-table__body tr {
    cursor: pointer;
  }

  &__number {
    color: #191C1F;
  }

  &__question {
    &__content {
      color: $color-ink-light;
      margin-bottom: $size-xxs;
    }
  }

  &__hit-rate {
    margin-bottom: $size-xxs;
    text-align: end;
  }

  &__hit-count {
    color: $color-ink-light;
  }
}
</style>
