<template>
  <div class="home-header">
    <div
      class="home-header__content"
      :style="{ justifyContent: navigation.length > 0 ? 'flex-start' : 'space-between' }"
    >
      <slot name="toolbar-right" />
      <div class="home-header__content__left">
        <div
          v-if="navigation.length > 0"
          class="home-header__content__breadcrumbs"
        >
          <Breadcrumbs
            :data="navigation"
          />
        </div>
        <h1 class="home-header__content__left__title">
          {{ title }}
        </h1>
        <p class="home-header__content__left__subtitle">
          {{ subtitle }}
        </p>
        <div class="home-header__content-container">
          <div class="home-header__content__select-classroom">
            <slot name="left" />
          </div>
        </div>
      </div>
      <div class="home-header__content__right">
        <slot name="right" />
      </div>
    </div>
    <div class="home-header__activities">
      <Tabs
        v-if="!isReport"
        :tabs="tabRoutes"
      />
      <ReportTabs
        v-else
        :tab-routes="reportTabs"
        :questionnaire="questionnaire"
        :loading="loading"
      />
    </div>
    <div class="home-header__divider" />
  </div>
</template>

<script>
import ReportTabs from 'MFE/views/SasActivities/partials/ClassReport/partials/ReportTabs'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import Tabs from '../Tabs/Tabs'

export default {
  name: 'Header',
  components: {
    Breadcrumbs, Tabs, ReportTabs,
  },
  props: {
    subjectIds: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    tabRoutes: {
      type: Array,
      default: () => [],
    },
    navigation: {
      type: Array,
      default: () => [],
    },
    isReport: {
      type: Boolean,
      default: false,
    },
    questionnaire: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    reportTabs: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.home-header, h1 {
  font-family: "Inter", sans-serif;
}

.home-header {
  max-width: 82%;
  margin: 0 auto;
  padding-top: $size-xl;
  margin-bottom: $size-l;

  &__divider {
    width: 100%;
    height: 1px;
    background-color: #E6E9ED;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @include mq_m {
    max-width: 91.5%;
  }

  &__content {
    padding-bottom: $size-l;
    display: flex;

    @include mq_m {
      flex-direction: column;
    }

    &__breadcrumbs {
      margin-bottom: $size-s;
      display: flex;
      justify-content: space-between;
    }

    &__select-classroom {
      margin-top: $size-m;
    }

    &__right {
      display: flex;
      align-self: flex-start;
    }

    &__left {
      margin-bottom: $size-m;

      @include mq-l__mf {
        padding: 0;
      }

      &__back-button {
        svg {
          color: #707780;
        }
      }

      &__title {
        font-weight: $font-weight-semi-bold;
        font-size: 2rem;
      }

      &__subtitle {
        color: #707780;
      }
    }
  }
}
</style>
