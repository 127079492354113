<template>
  <div class="students-view-cards">
    <div
      v-for="(item, index) in students"
      :key="index"
      class="students-view-cards__item"
      @click="selectStudent(item, index)"
    >
      <div class="students-view-cards__item__student">
        <div class="students-view-cards__item__student-top">
          <ProfilePicture
            :user="item"
          />
          <h6 class="students-view-cards__item__student__name">
            {{ item.name }}
          </h6>
        </div>
        <div class="students-view-cards__item__student__divider" />

        <div class="students-view-cards__item__student-bottom">
          <div class="students-view-cards__item__student-status">
            <h6 class="students-view-cards__item__student__status__title">
              Status
            </h6>
            <s-badge :variation="getFinishedStatusVariation(item.status)">
              <span :class="getFinishedStatusVariation(item.status)">
                {{ handleDiagnosticStatusText(item.status) }}
              </span>
            </s-badge>
          </div>
          <div class="students-view-cards__item__student__percentage">
            <h6>Percentual de acerto</h6>
            <span class="students-view-cards__item__student__percentage__value">
              {{ handleHitRateText(item.hitRate) }}
            </span>
          </div>

          <div class="students-view-cards__item__student__sessions">
            <h6>Sessões</h6>
            <span class="students-view-cards__item__student__sessions__value">
              {{ item.retryCount !== null ? item.retryCount : '-' }}
            </span>
          </div>

          <div class="students-view-cards__item__student__finished-at">
            <h6>Finalizado</h6>
            <span class="students-view-cards__item__student__finished-at">
              {{ format(item.finishedAt, 'dd MMM, yyyy - HH:mm') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from 'MFE/components/ProfilePicture'
import { getStatusVariation, studentAssignmentStatus } from 'MFE/utils/status'
import formatDate from 'MFE/mixins/formatDate'

export default {
  name: 'StudentsViewCards',
  components: {
    ProfilePicture,
  },
  mixins: [
    formatDate,
  ],
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      studentAssignmentStatus,
    }
  },
  methods: {
    getStatusVariation,
    selectStudent(data, index) {
      this.$emit('selectStudent', {
        data,
        index,
      })
    },
    getFinishedStatusVariation(finishedStatus) {
      switch (finishedStatus) {
        case 'Finalizado': return 'success'
        case 'NOT_FINISHED': return 'primary'
        case 'Não iniciado': return 'neutral'
        default: return null
      }
    },
    handleDiagnosticStatusText(diagnosticStatus) {
      return `• ${diagnosticStatus}` || ''
    },
    handleHitRateText(hitRate) {
      return hitRate > 0 ? `${hitRate}%` : '-'
    },
  },
}
</script>

<style lang="scss" scoped>
.students-view-cards__item__student__divider {
  height: 1px;
  width: 100%;
  background-color: #E6E9ED;
}
.students-view-cards {
  display: flex;
  gap: $size-s;
  align-items: center;

  @include mq_l {
    flex-direction: column;
  }

  &__item {
    @include space-stack($size-s);
    border: 1px solid #E6E9ED;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 16px;

    &__student {
      display: flex;
      flex-grow: 1;
      padding: $size-s;
      flex-wrap: wrap;

      &-top {
        @include flex-center-start;
        margin-bottom: $size-xs;
        gap: $size-xs;
      }

      &-bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: $size-xs;
        margin-top: 12px;

        @include mq_s {
          flex-direction: column;
        }

        h6 {
          font-size: 14px;
          font-weight: 400;
          line-height: 17.5px;
          color: #707780;
        }

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 17.5px;
        }
      }

      &__name {
        flex: 1 0 100%;
        margin-bottom: $size-xxs;
      }

      &__status {
        margin-right: $size-s;
      }
    }

    &__hit-rate {
      min-width: 62px;
      padding: $size-xs;

      &__value {
        display: block;
      }

      &__label {

        color: $color-ink-light;
      }
    }

  }

  ::v-deep .sas-badge--success,
  ::v-deep .sas-badge--primary,
  ::v-deep .sas-badge--neutral {
    background: none;
  }
}
</style>
