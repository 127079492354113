import axios from 'axios'

import {
  addAuthorizationHeader,
  handleResponseInterceptorError,
  addXCrossTokenHeader,
  addXAccountIdHeader
} from 'App/utils/auth'
import { urls } from 'shared/utils/urls'
import microfrontendService from '@/shared/utils/MicrofrontendService'

const isMicrofrontend = microfrontendService.get()

const apiBff = axios.create({
  baseURL: urls.ASSIGNMENT_REPORT_BFF,
  timeout: process.env.VUE_APP_TIMEOUT,
})

export const apiMock = axios.create({
  baseURL: urls.ASSIGNMENT_REPORT_BFF_MOCK,
  timeout: process.env.VUE_APP_TIMEOUT,
})

apiBff.interceptors.request.use(addAuthorizationHeader)

if (isMicrofrontend && (process.env.VUE_APP_MODE !== 'production')) {
  apiBff.interceptors.request.use(addXCrossTokenHeader)
}

apiBff.interceptors.request.use(addXAccountIdHeader)

apiBff.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default apiBff
