<template>
  <div
    class="answer-status-badge"
    :class="handleBadgeClass(row.answerStatus)"
  >
    <s-badge
      v-tooltip.top="tooltip"
      :icon="badgeIcon(row.answerStatus)"
      :variation="badgeVariation(row.answerStatus)"
    />
    <span> {{ resultLabel(row.answerStatus) }} </span>
  </div>
</template>

<script>
import badgeStyle from 'MFE/mixins/badgeStyle'
import answerStatusEnum from 'App/enums/answerStatus'

export default {
  name: 'AnswerStatusBadge',
  mixins: [ badgeStyle ],
  props: {
    row: {
      type: Object,
      required: false,
      default: null,
    },
    tooltip: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
  },
  methods: {
    resultLabel(answerStatus) {
      switch (answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'Acertou'
        case answerStatusEnum.WRONG:
          return 'Errou'
        case answerStatusEnum.BLANK:
          return 'Não fez'
        default:
          return ''
      }
    },
    handleBadgeClass(answerStatus) {
      switch (answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'sas-badge--success'
        case answerStatusEnum.WRONG:
          return 'sas-badge--danger'
        case answerStatusEnum.BLANK:
          return 'sas-badge--danger'
        default:
          return 'sas-badge--default'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.answer-status-badge {
  padding: 4px 12px;
  gap: 4px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 600;
  }
}
.sas-badge {
  &--default {
    background: $color-ink-lightest;
    color: $color-ink-light;
  }

  &--success {
    background: #9BE8BB;
    color: #198458;
  }
}
</style>
