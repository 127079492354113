<template>
  <div class="breadcrumbs">
    <SButton
      v-if="['x_small', 'small'].includes($mq)"
      variation="secondary"
      @click="goBack()"
    >
      <Icon
        size="16"
        type="arrow-left"
      />
    </SButton>
    <template v-else>
      <s-button
        v-for="(item, index) in route"
        :key="index"
        variation="secondary"
        :icon-right="index === 0 ? 'chevron-right' : null"
        :style="breadcrumbsStyle(index)"
        @click="goBack(item.route)"
      >
        {{ item.name }}
      </s-button>
    </template>
  </div>
</template>

<script>
import { SButton, Icon } from '@sas-te/alfabeto-vue'

export default {
  name: 'Breadcrumbs',
  components: {
    SButton,
    Icon,
  },
  props: {
    data: {
      type: Array,
      default: () => ([
        {
          label: '',
          route: {
            name: '',
            query: {},
          },
        },
      ]),
      required: false,
    },
  },
  computed: {
    route() {
      return this.data.map((item) => ({
        name: item.label,
        route: item.route,
      }))
    },
  },
  methods: {
    goBack(route) {
      if (route) {
        this.$router.push(route)
      } else {
        this.$router.go(-1)
      }
    },
    breadcrumbsStyle(index) {
      return {
        color: index === this.route.length - 1 ? '' : '#0467DB',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.breadcrumbs {
  @include flex-center-start;
  font-family: "Inter", sans-serif;

  .sas-button {
    position: relative;
    padding: 0;

    @include mq-xs {
      max-width: 100%;
    }

    ::v-deep .sas-button__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: $font-weight-semi-bold;
      font-family: "Inter", sans-serif;
    }

    ::v-deep .sas-button__icon {
      color: #707780;
    }
  }

  ::v-deep .sas-button.--secondary {
    border: none;
    background: none;
    box-shadow: none;
  }
}
</style>
