<template>
  <div
    class="input-wrapper"
    :class="inputClass"
  >
    <s-input
      :id="id"
      :icon-left="iconLeft"
      :width="width"
      :placeholder="placeholder"
      :value="value"
      :icon-color="iconColor"
      :type="type"
      :size="size"
      :icon-right="iconRight"
      :status="status"
      @input="$emit('input', $event)"
    />
    <SButton
      v-if="clearable"
      class="input-wrapper__clear-button"
      variation="tertiary"
      size="small"
      icon="x"
      @click="$emit('clear')"
    />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    clearable: Boolean,
    inputClass: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [ String, Date, Number ],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => value.match(/(small|medium|large)/),
    },
    iconRight: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: '#9EA4AC',
    },
    status: {
      type: String,
      default: null,
      validator: (value) => value.match(/(success|error|warning)/),
    },
    width: {
      type: String,
      default: '100%',
    },
    autofocus: Boolean,
    disabled: Boolean,
    required: Boolean,
    information: {
      type: String,
      default: null,
    },
    informationIcon: {
      type: String,
      default: 'info',
    },
    example: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  display: flex;
  max-height: 44px;

  &__clear-button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
::v-deep .sas-input__field {
  background-color: $color-white;
  box-shadow: none;

  &::placeholder {
    color: #9EA4AC;
  }
}
</style>
