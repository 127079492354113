<template>
  <div class="general-view">
    <section class="general-view__section">
      <div class="general-view__cards">
        <EngagementReport
          @no-students-started="showEmptyState"
          @no-students-finished="setNoStudentsFinishedHitRateLabel"
        />
        <HitRateReport
          :no-hit-rate-label="hitRateLabel"
        />
      </div>
    </section>
    <section class="general-view__section">
      <StudentsView
        ref="studentsView"
        :questionnaire="questionnaire"
        :students-statistics="studentsStatistics"
        :selected-classroom="selectedClassroom"
        :loading="loading"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { studentAssignmentStatus } from 'MFE/utils/status'
import mediaQueries from 'shared/mixins/mediaQueries'
import orderBy from 'lodash/orderBy'
import HitRateReport from './HitRateReport'
import EngagementReport from './EngagementReport'
import assessmentStatus from '../StudentsView/utils/assessmentStatus'
import assessmentFilter from '../StudentsView/utils/assessmentFilter'
import visualizeByEnum from '../StudentsView/enums/visualizeBy'
import StudentsView from '../StudentsView/StudentsView'

export default {
  name: 'GeneralView',
  components: {
    StudentsView,
    HitRateReport,
    EngagementReport,
  },
  mixins: [ mediaQueries ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
    selectedClassroom: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
    isChallengeActivity: Boolean,
    isTeacherChallenge: Boolean,
    isChallengeReinforcement: Boolean,
    studentsStatistics: {
      type: Object,
      default: () => ({}),
    },
    isEurekaGradeSelectedClassroom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noStudentStarted: false,
      enableToShare: true,
      hitRateLabel: '',
    }
  },
  computed: {
    ...mapGetters([ 'shareUrlCache' ]),
    isArenaMission() {
      return this.isChallengeActivity || this.isTeacherChallenge || this.isChallengeReinforcement
    },
    gradeId() {
      return this.selectedClassroom?.grade.id
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    studentsFinishedSectionTitle() {
      if (this.isChallengeActivity) {
        return 'Alunos que jogaram'
      }

      return 'Alunos que finalizaram a tarefa'
    },
    studentsFinished() {
      if (this.studentsStatistics.loading) {
        return []
      }

      return this.studentsStatistics.data?.map((statistic) => {
        const sortedAssignments = orderBy(statistic.assignments, [ 'retryCount' ], [ 'asc' ])
        const moreRecentAssignment = assessmentFilter(
          sortedAssignments, visualizeByEnum.SESSION_MORE_RECENT
        )

        const status = assessmentStatus(moreRecentAssignment)
        const startedAt = moreRecentAssignment?.startedAt
          ? new Date(moreRecentAssignment.startedAt)
          : null
        const finishedAt = moreRecentAssignment?.finishedAt
          ? new Date(moreRecentAssignment.finishedAt)
          : null

        return {
          ...statistic.student,
          ...statistic,
          ...moreRecentAssignment,
          status,
          startedAt,
          finishedAt,
        }
      }).filter(
        (student) => student.status === studentAssignmentStatus.FINISHED
      ) ?? []
    },
    classroomId() {
      return this.$route.params.classroomId
    },
    emptyState() {
      const image = 'assignments-not-found'

      if (this.isChallengeActivity) {
        return {
          image,
          title: 'Nenhum aluno participou da missão',
          description: 'O relatório só é gerado quando pelo menos um aluno entra na partida',
        }
      }

      return {
        image,
        title: 'Nenhum aluno desta turma iniciou a atividade',
        description: 'Compartilhe o link abaixo com os alunos para começarem a responder!',
      }
    },
  },
  watch: {
    noStudentStarted(value) {
      if (value) {
        if (this.isChallengeActivity || this.isTeacherChallenge || this.isChallengeReinforcement) {
          this.enableToShare = false
        } else {
          const { questionnaireCode, gradeId } = this
          this.getShareUrl({ questionnaireCode, gradeId })
        }
      }
    },
    classroomId() {
      this.noStudentStarted = false
    },
  },
  methods: {
    ...mapActions([ 'getShareUrl' ]),
    showEmptyState() {
      this.noStudentStarted = true
    },
    setNoStudentsFinishedHitRateLabel() {
      this.hitRateLabel = 'no-student-finished'
    },
  },
}
</script>

<style lang="scss" scoped>
.general-view{
  &__section {
    @include space-stack($size-xl);
  }

  &__cards {
    display: grid;
    grid-gap: $size-s;

    @include mq-m--mf {
      grid-gap: $size-m;
      grid-template-columns: 5fr 3fr;
    }

    @include mq-l--mf {
      grid-template-columns: 7fr 5fr;
    }

    &__item {
      padding: $size-s !important;
      @include flex-center-start;
      flex-wrap: wrap;
    }
  }

  &__empty {
    @include flex-column-center;
  }
}

.empty-state {
  padding: 0;
}

.share-url {
  max-width: 450px;
}
</style>
