<template>
  <div
    v-if="!hasError && !loading"
    class="engagement-report"
  >
    <h4 class="engagement-report__title">
      Participação
    </h4>

    <ApexCharts
      type="donut"
      :options="chartOptions"
      :series="series"
      height="100%"
    />
  </div>
</template>

<script>
import simplur from 'simplur'
import { mapGetters, mapActions } from 'vuex'
import ApexCharts from 'vue-apexcharts'
import { isNil } from 'lodash'

import { colorsByStatus, engagementClassroomStatus } from 'App/utils/status'
import mediaQueries from 'shared/mixins/mediaQueries'
import statisticsApi from '@/service/statistics'
import accentFold from 'App/mixins/accentFold'
import applicationTypeEnum from 'App/views/AncestralArena/enums/applicationTypeEnum'
import missionStatusEnum from 'App/views/AncestralArena/enums/missionStatusEnum'

export default {
  name: 'EngagementReport',
  components: {
    ApexCharts,
  },
  mixins: [ mediaQueries, accentFold ],
  data() {
    return {
      engagement: {},
      hasError: false,
      loading: false,
      questionnaire: {},
    }
  },
  computed: {
    ...mapGetters([
      'yearSelectedContent',
    ]),
    studentsTotalAmount() {
      const total = this.engagement?.studentsTotalAmount

      if (!total) {
        return ''
      }

      return simplur`Total de ${total} [aluno|alunos]`
    },
    engagementList() {
      const isHomeApplication = this.questionnaire?.applicationType === applicationTypeEnum.HOME
      const isLiveApplication = this.questionnaire?.applicationType === applicationTypeEnum.LIVE
      const isMissionOngoing = this.questionnaire?.finishedStatus === missionStatusEnum.ONGOING
      const isMissionFinished = this.questionnaire?.finishedStatus === missionStatusEnum.FINISHED

      if (isHomeApplication) {
        if (isMissionOngoing) {
          return this.filterEngagementByStatus(engagementClassroomStatus.INCOMPLETE)
        }
        if (isMissionFinished) {
          return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
        }
      }

      if (isLiveApplication && isMissionFinished) {
        return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
      }

      return this.engagement?.engagementRange ?? []
    },
    barChartList() {
      const isHomeApplication = this.questionnaire?.applicationType === applicationTypeEnum.HOME
      const isLiveApplication = this.questionnaire?.applicationType === applicationTypeEnum.LIVE
      const isMissionOngoing = this.questionnaire?.finishedStatus === missionStatusEnum.ONGOING
      const isMissionFinished = this.questionnaire?.finishedStatus === missionStatusEnum.FINISHED

      if (isHomeApplication) {
        if (isMissionOngoing) {
          return this.filterEngagementByStatus(engagementClassroomStatus.INCOMPLETE)
            .filter(({ rate }) => rate !== 0)
        }
        if (isMissionFinished) {
          return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
            .filter(({ rate }) => rate !== 0)
        }
      }

      if (isLiveApplication && isMissionFinished) {
        return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
          .filter(({ rate }) => rate !== 0)
      }

      return this.engagement?.engagementRange ?? []
    },
    series() {
      return this.barChartList.map(({ rate }) => rate)
    },
    chartLabels() {
      const label = this.barChartList.map(({ name }) => name)
      const itemRate = this.barChartList.map(({ rate }) => rate)

      return label.map((item, index) => `${itemRate[index]}% ${item}`)
    },
    chartOptions() {
      return {
        chart: {
          type: 'donut',
          width: '100%',
          height: '200px',
        },
        labels: this.chartLabels,
        colors: [ '#54C985', '#F47B7B', '#2E93FA', '#E8E8E8' ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          horizontalAlign: 'left',
          position: this.mq_s ? 'bottom' : 'right',
          fontSize: '14px',
          fontFamily: 'Inter',
          markers: {
            width: 20,
            height: 20,
            radius: 0,
          },
          formatter(seriesName, opts) {
            const percentage = opts.w.globals.seriesPercent[opts.seriesIndex]
            const value = opts.w.globals.series[opts.seriesIndex]
            if (typeof percentage !== 'number') {
              return seriesName
            }

            return `
          <div style="display: flex; flex-direction: column; margin-bottom: 8px;">
            <div>
              <strong style="font-size: 16px;">${value}</strong>
              <span style="font-size: 14px; color: #707780;"> (${percentage}%)</span>
            </div>
            <span>${seriesName}</span>
          </div>`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
      }
    },
    classroomId() {
      return this.$route.params.classroomId
    },
  },
  watch: {
    classroomId: {
      immediate: true,
      handler() {
        this.getEngagement()
      },
    },
  },
  created() {
    this.getQuestionnaire()
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    barStyle(item) {
      return {
        width: `${item.rate}%`,
        background: item.color,
      }
    },
    filterEngagementByStatus(status) {
      return this.engagement?.engagementRange?.filter(({ name }) => name !== status) ?? []
    },
    getLegendColor(label) {
      const normalizeLabel = this.accentFold(label.split(' ').join('_')).toLowerCase()

      return colorsByStatus({ tokens: this.$tokens, status: normalizeLabel })
    },
    async getQuestionnaire() {
      const { questionnaireCode } = this.$route.params

      try {
        const response = await statisticsApi.getQuestionnaire(questionnaireCode)
        this.questionnaire = response
      } catch (error) {
        this.questionnaire.error = true
      }
    },
    checkStudentsEngagement() {
      const { engagementRange } = this.engagement
      const { rate: notStartedRate } = engagementRange
        .find(({ name }) => name === engagementClassroomStatus.NOT_STARTED)
      const finishedStudents = engagementRange
        .find(({ name }) => name === engagementClassroomStatus.FINISHED)

      if (finishedStudents?.rate === 0) {
        this.$emit('no-students-finished')
      }

      if (notStartedRate === 100) {
        this.$emit('no-students-started')
      }
    },
    mapEngagementRange(data) {
      return data.engagementRange.map((item) => ({
        ...item,
        color: this.getLegendColor(item.name),
      }))
    },
    async getEngagement() {
      const { questionnaireCode, classroomId } = this.$route.params

      if (!questionnaireCode) {
        console.error('Questionnaire code or classroom id not found', questionnaireCode, classroomId)

        return
      }

      if (!classroomId) {
        console.error('classroomId is null')

        await this.getClassrooms(this.yearSelectedContent)
      }

      this.loading = true
      try {
        const isRouteTypeBookActivities = this.$route.query.type === 'book-activities'
        const isBookActivities = (!isNil(this.$route.query.type) && isRouteTypeBookActivities)
        const getEngagementStatistics = isBookActivities
          ? statisticsApi.getEngagementStatistics
          : statisticsApi.getEngagementStatisticsBff
        const currentYear = new Date().getFullYear()

        const { data } = await getEngagementStatistics({
          questionnaireCode,
          classroomId,
          contentYear: this.yearSelectedContent ?? currentYear,
        })

        this.engagement = {
          ...data,
          engagementRange: this.mapEngagementRange(data),
        }

        this.checkStudentsEngagement()
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.engagement-report {
  border: 1px solid #E6E9ED;
  border-radius: 16px;
  padding: $size-m;

  @include mq_s {
    width: 91% !important;
  }

  ::v-deep .apexcharts-legend.apx-legend-position-right {
    flex-direction: row;
    margin-top: -24px;
  }

  ::v-deep .apexcharts-datalabel-label {
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    line-height: 17.5px;
  }
  ::v-deep .apexcharts-legend-series-marker {
    border-radius: 0 !important; /* Force square markers */
    width: 12px !important; /* Adjust size if necessary */
    height: 12px !important;
  }
  ::v-deep .apexcharts-datalabel-value {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
  }

  &__title {
    flex: 1 0 auto;
    font-family: "Inter", sans-serif;
    margin-bottom: $size-m;
    font-weight: 600;
  }

  &__students-count {
    color: $color-ink-light;
  }

  &__chart {
    flex: 1 0 100%;
    height: $size-m;
    margin: $size-s 0;
    border-radius: $size-xxs;
    @include flex-center;

    ::v-deep .apexcharts-legend-series-marker {
      border-radius: 0 !important; /* Square markers */
      width: 20px !important; /* Adjust size */
      height: 20px !important;
    }

    &__bar {
      height: 100%;
      border-right: 3px solid $color-white;

      &:first-child {
        border-top-left-radius: $size-xxs;
        border-bottom-left-radius: $size-xxs;
      }

      &:last-child {
        border: none;
        border-top-right-radius: $size-xxs;
        border-bottom-right-radius: $size-xxs;
      }
    }
  }
  &__legend {
    @include mq-media--mf(1024px) {
      @include flex-center;
    }

    &__item {
      margin-top: $size-xs;
      @include flex-center-start;

      @include mq-media--mf(1024px) {
        margin-top: 0;
        @include space-inline($size-s);
      }

      @include mq-media--mf(1080px) {
        @include space-inline($size-l);
      }

      &__bullet {
        width: $size-s;
        height: $size-s;
        display: inline-block;
        border-radius: 50%;
        margin-right: $size-xs;
      }

      &__label {
        margin-right: $size-xxs;
      }
    }
  }
}
</style>
