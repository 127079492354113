<template>
  <div :class="[ 'question-card__item', `--${variation}`]">
    <div class="question-card__item__question">
      <div
        v-if="questionNumberIsVisible"
        class="question-card__item__number"
      >
        <span>
          {{ question.order }}
        </span>
      </div>
      <span
        v-if="mustShowSubject"
        class="question-card__item__question__content"
      >
        {{ question.content.name }}
      </span>
      <h6 class="question-card__item__question__statement">
        {{ truncateText(question.statement, statementMaxLength) }}
      </h6>
    </div>

    <div class="question-card__divider" />

    <div class="question-card__item__details">
      <div class="question-card__item__details-levels">
        <span> Dificuldade </span>
        <p> {{ $t( `levels.${question.level}`) }} </p>
      </div>

      <div class="question-card__item__details-hit-rate">
        <span> Percentual de acerto </span>
        <b> {{ question.hitRate !== null ? `${Math.round(question.hitRate)}%` : '-' }} </b>
      </div>
    </div>
  </div>
</template>

<script>
import truncateText from 'shared/utils/truncateText'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'QuestionsCard',
  mixins: [ subjectVisibilityToggle, mediaQueries ],
  props: {
    question: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: 'neutral',
      validator: (value) => value.match(/(neutral|warning)/),
    },
    questionNumberIsVisible: Boolean,
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 16
        case 'small':
          return 28
        case 'medium':
          return 43
        case 'large':
          return 60
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
  },
  methods: {
    truncateText,
  },
}
</script>

<style lang="scss" scoped>
.question-card__divider {
  height: 1px;
  background-color: #E6E9ED;
  margin: 0 16px;
}

.question-card__item {
  @include space-stack($size-s);
  border: 1px solid #E6E9ED;
  border-radius: $size-xs;

  &.--warning {
    box-shadow: 0 0 0 2px #794C00;
  }

  &__number {
    width: $size-m;
    height: $size-m;
    background-color: #E6E9ED;
    color: #525860;
    border-radius: $size-l;
    @include flex-center;
    flex-shrink: 0;
    margin: 0 $size-s;

    span {
      font-weight: 600;
    }

    .--warning & {
      background-color: $color-warning-lightest;

      span {
        color: $color-warning-darkest;
      }
    }
  }

  &__question {
    flex-grow: 1;
    padding: $size-s;
    left: 12px;
    display: flex;
    align-items: center;

    &__content {
      margin-bottom: $size-xs;
      color: $color-ink-light;
      display: inline-block;
    }
  }

  &__details {
    min-width: $size-xxl;
    padding: $size-s;
    display: flex;

    &-levels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: $size-l;

      span {
        color: #707780;
      }

      p {
        font-weight: 600;
        font-size: $font-size-s;
        color: #191C1F;
      }
    }

    &-hit-rate {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        color: #707780;
      }

      b {
        font-weight: 600;
        font-size: $font-size-s;
        color: #191C1F;
      }
    }
  }
}
</style>
