<template>
  <div class="report-problem">
    <ResponsiveReportComponent
      :complaints="complaintTypes.data"
      :loading="complaint.loading"
      :close-disabled="disableCloseReport"
      @close="$emit('close')"
      @sending-report="sendAReport"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ResponsiveReportComponent from 'MFE/components/ReportProblem/ResponsiveReportComponent'

export default {
  name: 'ReportProblem',
  components: {
    ResponsiveReportComponent,
  },
  data() {
    return {
      disableCloseReport: false,
    }
  },
  computed: {
    ...mapGetters([
      'complaintTypes',
      'complaint',
    ]),
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    questionId() {
      const { question, $route } = this
      const { activeQuestion } = $route.query

      if (activeQuestion) {
        return activeQuestion
      }

      return question.data.id
    },
    classroomId() {
      return this.$route.params.classroomId
    },
  },
  created() {
    this.getComplaintTypes()
  },
  destroyed() {
    this.clearComplaints()
  },
  methods: {
    ...mapActions([
      'getComplaintTypes',
      'sendIssueComplaint',
      'clearComplaints',
    ]),
    async sendAReport(report) {
      const { questionnaireCode, questionId, classroomId } = this
      const { description, complaintTypes } = report
      const TRANSITION_TIME = 1000
      this.disableCloseReport = true

      try {
        await this.sendIssueComplaint({
          questionnaireCode,
          questionId,
          classroomId,
          complaint: {
            description,
            complaintTypes,
          },
        })
      } finally {
        setTimeout(() => {
          this.$emit('report-succeed')
          this.disableCloseReport = false
        }, TRANSITION_TIME)
      }
    },
  },
}
</script>
