<template>
  <div
    :class="[
      'sas-select',
      {
        'sas-select--active': active,
        'sas-select--disabled': disabled,
      },
    ]"
  >
    <label
      v-if="label"
      class="label"
      v-text="label"
    />

    <CustomDropdown
      :class="['sas-select__dropdown', { 'sas-select--empty': !value }]"
      :disabled="disabled"
      :dropdown-class="`sas-select__dropdown-menu`"
      :icon="iconRight"
      :icon-left="iconLeft"
      :loading="loading"
      :open="active"
      origin="bottom-left"
      :size="size"
      :text="getValue(value) || placeholder"
      :variation="variation"
      @toggle="toggle($event)"
    >
      <div
        v-if="searchable"
        class="sas-select__search"
      >
        <Input
          v-model="search"
          icon-left="search"
          :placeholder="searchPlaceholder"
          :size="size"
          width="100%"
        />
      </div>
      <CustomDropdownItem
        v-for="option of filteredOptions"
        :key="getValue(option)"
        :class="['sas-select__option', { 'sas-select__option--selected': isSelected(option) }]"
        role="option"
        @click="select(option)"
      >
        {{ getValue(option) }}
      </CustomDropdownItem>
      <p
        v-if="search && filteredOptions.length === 0"
        class="sas-select__empty-search"
      >
        Nenhum resultado encontrado para
        <strong class="sas-select__empty-search_term">{{ search }}</strong>
      </p>
    </CustomDropdown>
  </div>
</template>

<script>
import accentFold from 'MFE/mixins/accentFold'

import Input from './Input/Input'
import CustomDropdown from './CustomDropdown/CustomDropdown'
import CustomDropdownItem from './CustomDropdown/CustomDropdownItem'

export default {
  name: 'Select',
  components: {
    Input,
    CustomDropdown,
    CustomDropdownItem,
  },
  mixins: [ accentFold ],
  model: {
    event: 'change',
  },
  props: {
    /**
     * Select's sizes
     * @values `small, medium, large`
     */
    size: {
      type: String,
      default: 'medium',
      validator: (value) => value.match(/(small|medium|large)/),
    },
    /**
     * Toggle's variations
     * @values `primary, secondary, danger`
     */
    variation: {
      type: String,
      default: 'secondary',
      validator: (value) => value.match(/(primary|secondary|danger)/),
    },
    /**
     * @model
     */
    value: {
      type: null,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    searchable: Boolean,
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    searchPlaceholder: {
      type: String,
      default: 'Pesquisar',
    },
    trackBy: {
      type: String,
      default: null,
    },
    loading: Boolean,
    iconRight: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: false,
      search: '',
    }
  },
  computed: {
    filteredOptions() {
      if (!this.search) {
        return this.options
      }
      const query = this.accentFold(this.search.toLowerCase())

      return this.options
        .filter((data) => this.accentFold(this.getValue(data).toLowerCase()).includes(query))
    },
  },
  methods: {
    toggle(value) {
      this.active = value
    },
    select(option) {
      const value = this.isSelected(option)
        ? null
        : option

      this.$emit('change', value)
      this.close()
    },
    close() {
      this.active = false
      this.search = ''
    },
    getValue(value) {
      if (!value) {
        return false
      }

      return this.trackBy ? value[this.trackBy] : value
    },
    isSelected(option) {
      return this.value === option
    },
  },
}
</script>

<style lang="scss">
.sas-select {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;

  &:focus {
    outline: 0;
  }
}

.sas-select__dropdown {
  display: block;
  width: 100%;
  max-width: 100%;

  .sas-dropdown__button:not(.sas-select--primary),
  .sas-dropdown-menu__item {
    color: $color-ink;
    box-shadow: none !important;
  }
}

.sas-select--empty:not(.sas-select--primary) .sas-dropdown__button {
  color: $color-ink-lighter;
  @include transition($speed-x-fast);
}

.sas-select__dropdown-menu {
  position: absolute;
  justify-content: unset;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.sas-select__search {
  width: 100%;
  margin-top: -#{$size-xxs};
  margin-bottom: $size-xxs;
  @include space-inset(12px);
}

.sas-select__option--selected {
  background: rgba($color-ink, .15);

  &:hover {
    background: rgba($color-ink, .25);
  }
}

.sas-select__empty-search {
  max-width: 320px;
  color: $color-ink-lighter;
  @include space-inset($size-s);
}

.sas-select__empty-search_term {
  color: $color-ink-light;
}

.sas-select__search-box {
  width: 100%;
  padding: $size-s;
}
</style>
