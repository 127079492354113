<template>
  <div class="drawer-navigator">
    <header class="drawer-navigator__header">
      <slot name="header" />
      <div class="drawer-navigator__header__controls">
        <template v-if="!hideMoreOptions">
          <div class="drawer-navigator__header__controls__close">
            <s-button
              icon="x"
              variation="tertiary"
              @click="$emit('close')"
            />
          </div>
        </template>
      </div>
    </header>
    <slot name="child" />
  </div>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'DrawerNavigator',
  mixins: [ mediaQueries ],
  props: {
    hasNext: {
      type: Boolean,
      default: false,
    },
    hasPrevious: {
      type: Boolean,
      default: false,
    },
    hideNavigation: {
      type: Boolean,
      default: false,
    },
    hideMoreOptions: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<!-- eslint-disable max-len -->
<style lang="sass" scoped>
.drawer-navigator
  &__header
    padding: $size-s
      left: $size-xl
    +flex-space-between

    &__controls
      +flex-center

      &__previous
        padding:
          right: $size-xs

      &__next
        padding:
          left: $size-xs
          right: $size-xs

      &__close
        padding:
          left: $size-xs

      &__dropdown
        padding: 0 $size-xs

.--border-left
  border-left: 1px solid rgba($color-ink-lightest, 0.5)
</style>
