<template>
  <div class="sas-table-container">
    <div class="sas-table-container__container">
      <table
        class="sas-table"
      >
        <thead
          v-if="!loading && fields.length"
          class="sas-table__header"
        >
          <tr>
            <th
              v-for="(field, index) in fields"
              :key="index"
              :class="{'--sortable': field.sortable }"
              :style="{
                minWidth: field.minWidth || '',
                width: field.width || '',
                whiteSpace: field.whiteSpace || '',
                overflow: field.overflow || '',
                textOverflow: field.textOverflow || '',
              }"
              @click="field.sortable ? updateSort(field.value) : null"
            >
              <div class="sas-table__header__field">
                {{ field.text }}
                <icon
                  v-if="field.value === sortField && field.sortable"
                  size="16"
                  :class="[
                    'sas-table__header__field__icon',
                    `--${sortDirection}`
                  ]"
                  type="arrow-down"
                />
              </div>
            </th>
          </tr>
        </thead>
        <thead
          v-else-if="fields.length"
          class="sas-table__header"
        >
          <tr>
            <th
              v-for="(field, index) in fields"
              :key="index"
              :style="{
                minWidth: field && field.minWidth || '',
                width: field && field.width || ''
              }"
            >
              <div class="sas-table__header__field">
                <skeleton-loader
                  width="50%"
                  height="16px"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="!loading"
          class="sas-table__body"
        >
          <tr
            v-for="(row, index) in visibleContent"
            :key="index"
            :tabindex="index"
            :class="{ '--active': index === selectedVisibleIndex }"
            @click="rowClick(row, index)"
            @keydown.enter="rowClick(row, index)"
          >
            <td
              v-for="(field, fieldIndex) in fields"
              :key="fieldIndex"
              :style="{ 'text-align': field.textAlign || '' }"
            >
              <template v-if="!$scopedSlots[field.value]">
                <template v-if="formatData">
                  {{ formatData(row[field.value]) }}
                </template>
                <template v-else>
                  {{ row[field.value] }}
                </template>
              </template>
              <slot
                v-else
                :name="field.value"
                :row="row"
              />
            </td>
          </tr>
        </tbody>
        <tbody
          v-else
          class="sas-table__body"
        >
          <tr
            v-for="index in skeletonLines"
            :key="index"
          >
            <td
              v-for="(field, fieldIndex) in fields"
              :key="fieldIndex"
            >
              <slot :name="field ? field.value : fieldIndex" />
            </td>
          </tr>
        </tbody>
        <tfoot
          v-if="footer.length"
          class="sas-table__footer"
        >
          <slot name="footer" />
        </tfoot>
      </table>
    </div>
    <SPagination
      v-if="paginate"
      ref="pagination"
      :total-items="content.length"
      :items-per-page="itemsPerPage"
      :page="pagination.page"
      @range-change="rangeChange"
      @page-change="pageChange"
    >
      <template slot-scope="props">
        <slot
          name="pagination"
          :start="props.start"
          :end="props.end"
          :total="props.total"
        />
      </template>
    </SPagination>
  </div>
</template>

<script>
import SPagination from 'App/components/SPagination'

export default {
  name: 'STable',
  components: {
    SPagination,
  },
  props: {
    customTableHeaderClass: {
      type: String,
      default: '',
    },
    content: {
      type: Array,
      default: () => [],
    },
    selectedRow: {
      type: Object,
      default: null,
      required: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    footer: {
      type: Array,
      default: () => [],
    },
    defaultSort: {
      type: Object,
      default: () => ({
        field: '',
        direction: 'asc',
      }),
    },
    formatData: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    skeletonLines: {
      type: Number,
      default: 1,
    },
    elevation: {
      type: [ String, Number ],
      default: 1,
    },
    paginate: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      sortField: this.defaultSort.field,
      sortDirection: this.defaultSort.direction,
      pagination: {
        page: 0,
        start: 0,
        end: this.itemsPerPage,
      },
    }
  },
  computed: {
    defaultSortField() {
      return this.defaultSort.field
    },
    defaultSortModifier() {
      return this.defaultSort.direction === 'asc' ? 1 : -1
    },
    defaultSortFunction() {
      return this.fields.find(
        (field) => field.value === this.defaultSort.field
      )?.sortFunction ?? null
    },
    sortModifier() {
      return this.sortDirection === 'desc' ? -1 : 1
    },
    sortedContent() {
      if (!this.sortField || !this.sortDirection) {
        return this.content
      }

      const sortField = this.fields.find(
        (field) => field.value === this.sortField
      )

      return [ ...this.content ].sort(
        sortField.sortFunction
          ? (a, b) => {
            const order = sortField.sortFunction(
              a[this.sortField],
              b[this.sortField],
              this.sortModifier
            )
            if (!this.defaultSort.field || order !== 0) return order

            return this.defaultSortFunction
              ? this.defaultSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
                this.defaultSortModifier
              )
              : this.fallbackSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
                this.defaultSortModifier
              )
          }
          : (a, b) => {
            const order = this.fallbackSortFunction(
              a[this.sortField],
              b[this.sortField],
              this.sortModifier
            )
            if (!this.defaultSort.field || order !== 0) return order

            return this.defaultSortFunction
              ? this.defaultSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
                this.defaultSortModifier
              )
              : this.fallbackSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
                this.defaultSortModifier
              )
          }
      )
    },
    slicedContent() {
      return this.sortedContent.slice(
        this.pagination.start,
        this.pagination.end
      )
    },
    visibleContent() {
      return this.paginate
        ? this.slicedContent
        : this.sortedContent
    },
    selectedDataIndex() {
      return this.sortedContent.findIndex((row) => (
        row === this.selectedRow
      ))
    },
    selectedVisibleIndex() {
      return this.visibleContent.findIndex((row) => (
        row === this.selectedRow
      ))
    },
    totalPages() {
      if (this.content.length === 0) {
        return 0
      }

      return Math.ceil(this.content.length / this.itemsPerPage)
    },
  },
  watch: {
    content() {
      this.pagination = {
        page: 0,
        start: 0,
        end: this.itemsPerPage,
      }

      if (this.selectedRow) {
        this.rowClick(
          this.visibleContent[this.selectedVisibleIndex],
          this.selectedVisibleIndex,
          this.selectedDataIndex
        )
      }
    },
  },

  methods: {
    updateSort(field) {
      if (field === this.sortField) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      }
      this.sortField = field
      this.$emit('sorted', {
        field: this.sortField,
        direction: this.sortDirection,
      })
    },
    fallbackSortFunction(a, b, modifier = 1) {
      if (a < b) return -1 * modifier
      if (a > b) return modifier

      return 0
    },
    pageChange(page) {
      this.pagination.page = page
    },
    rangeChange(start, end) {
      this.pagination.start = start
      this.pagination.end = end
    },
    rowClick(data, visibleIndex, dataIndexParam) {
      const dataIndex = dataIndexParam || this.sortedContent.findIndex(
        (row) => row === data
      )
      this.$emit('click-row', { data, dataIndex, visibleIndex })
    },
    next() {
      const nextDataIndex = this.selectedDataIndex + 1
      if (this.selectedVisibleIndex < this.visibleContent.length - 1) {
        const nextVisibleIndex = this.selectedVisibleIndex + 1
        this.rowClick(
          this.visibleContent[nextVisibleIndex],
          nextVisibleIndex,
          nextDataIndex
        )
      } else if (this.pagination.page < this.totalPages - 1) {
        this.$refs.pagination.pageChange(this.pagination.page + 1)
        const nextVisibleIndex = 0
        this.rowClick(
          this.visibleContent[nextVisibleIndex],
          nextVisibleIndex,
          nextDataIndex
        )
      }
    },
    previous() {
      const previousDataIndex = this.selectedDataIndex - 1
      if (this.selectedVisibleIndex > 0) {
        const previousVisibleIndex = this.selectedVisibleIndex - 1
        this.rowClick(
          this.visibleContent[previousVisibleIndex],
          previousVisibleIndex,
          previousDataIndex
        )
      } else if (this.pagination.page > 0) {
        this.$refs.pagination.pageChange(this.pagination.page - 1)
        const previousVisibleIndex = this.visibleContent.length - 1
        this.rowClick(
          this.visibleContent[previousVisibleIndex],
          previousVisibleIndex,
          previousDataIndex
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.sas-table {
  font-family: "Inter";
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  transition: all 200ms ease-in-out;
  border: 1px solid #E6E9ED;
  box-sizing: border-box;
  border-radius: 4px;

  td,
  th {
    border-bottom: 1px solid #E6E9ED;
    font-family: "Inter" !important;
  }

  td {
    box-sizing: border-box;
    padding: $size-s $size-m;
    font-size: 16px;
    transition: all 200ms ease-in-out;
  }

  th {
    text-align: left;
    padding: 12px 12px;

    @include mq-m--mf {
      padding: 12px $size-m;
    }
  }

  tr {
    transition: all 200ms ease-in-out;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: transparentize($color-primary-lightest, 0.5);
    }

    &:last-child td {
      border-bottom: none;
    }

    &.--active {
      background-color: transparentize($color-primary-lightest, 0.5);
    }
  }

  &__header {
    &__field {
      height: $size-s;
      @include flex-center-start;

      &__icon {
        margin-left: $size-xxs;
        transition: all 200ms;
        flex-shrink: 0;

        &.--desc {
          transform: scaleY(-1);
        }
      }
    }
  }

  tbody,
  tfoot {
    tr:last-child th {
      border-bottom: none;
    }
  }

  thead {
    text-align: left;

    tr:first-child th:first-child {
      border-top-left-radius: 4px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 4px;
    }

    th {
      background: #F7F9FA;
      box-sizing: border-box;
      color: $color-ink-light;
      border-bottom: 1px solid #E6E9ED;
      font-size: 14px;
      font-weight: 600;
      vertical-align: middle;

      &.--sortable {
        cursor: pointer;
      }

      small {
        font-weight: 600;
        user-select: none;
      }
    }
  }

  tfoot {
    tr:first-child td,
    tr:first-child th {
      border-top: 1px solid #E6E9ED;
    }
  }

  &.--vertical-lines {
    td:not(:last-child),
    th:not(:last-child) {
      border-right: 1px solid #E6E9ED;
    }
  }
}

</style>
