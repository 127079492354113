export const studentAssignmentStatus = {
  NOT_STARTED: 'Não iniciado',
  IN_PROGRESS: 'Em andamento',
  FINISHED: 'Finalizado',
  INCOMPLETE: 'Incompleto',
}

export const engagementClassroomStatus = {
  FINISHED: 'Finalizaram',
  IN_PROGRESS: 'Em andamento',
  STARTED: 'Iniciaram',
  NOT_STARTED: 'Não iniciaram',
  INCOMPLETE: 'Incompleto',
}

export function getStatusVariation(status) {
  switch (status) {
    case 'Não iniciado': return 'neutral'
    case 'Não iniciaram': return 'neutral'
    case 'Em andamento': return 'warning'
    case 'Finalizado': return 'success'
    case 'Finalizaram': return 'success'
    case 'Incompleto': return 'neutral'
    default: return ''
  }
}

export const colorsByStatus = ({ status }) => {
  switch (status) {
    case 'finalizaram': return '#54C985'
    case 'finalizado': return '#54C985'
    case 'em_andamento': return '#2E93FA'
    case 'nao_iniciaram': return '#E8E8E8'
    case 'nao_iniciado': return '#E8E8E8'
    case 'incompleto': return '#E8E8E8'
    default: return ''
  }
}
