<template>
  <header class="students-view-header">
    <div class="students-view-header__title">
      <h3 v-if="!loading">
        Desempenho por estudante
      </h3>
      <skeleton-loader
        v-else
        width="350px"
        height="38px"
      />
    </div>
    <div class="students-view-header__subtitle">
      <p v-if="!loading">
        {{ studentsQuantity }}
      </p>
      <skeleton-loader
        v-else
        width="100px"
        height="22px"
      />
    </div>
  </header>
</template>

<script>
import simplur from 'simplur'

export default {
  name: 'StudentsViewHeader',
  props: {
    studentsCount: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    studentsQuantity() {
      return simplur`${this.studentsCount} [estudante|estudantes]`
    },
  },
}
</script>

<style lang="sass" scoped>
.students-view-header
  margin-bottom: $size-m
  display: flex
  flex-direction: column

  &__subtitle
    color: $color-ink-light

</style>
