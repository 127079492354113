<template>
  <div class="recomposition-progression-path__card">
    <h4 class="recomposition-progression-path__card-title">
      Desempenho
    </h4>
    <div
      v-if="!loading"
      class="recomposition-progression-path__card__chart"
    >
      <h2>{{ number }}%</h2>
      <ApexCharts
        class="recomposition-progression-path__card__chart__apexchart"
        type="bar"
        :options="chartOptions"
        :series="chartSeries"
        height="60px"
      />
    </div>
    <p class="recomposition-progression-path__card__chart__chart__label">
      Percentual de estudantes que finalizaram
    </p>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'

export default {
  name: 'RecompositionProgressionPathCard',
  components: {
    ApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      default: 0,
    },
    donutSize: {
      type: Number,
      default: 100,
    },
    startColor: {
      type: String,
      default: '#438DE4',
    },
    stopColor: {
      type: String,
      default: '#438DE4',
    },
  },
  computed: {
    chartSeries() {
      return [
        {
          name: 'Acertos',
          data: [ this.number ],
        },
      ]
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            barHeight: '10px',
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: [ '#54C985' ],
          opacity: 1,
        },
        xaxis: {
          max: 100,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          show: false,
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          borderColor: '#fff',
          row: {
            colors: [ '#F7F9FA' ],
          },
        },
        tooltip: {
          enabled: false,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recomposition-progression-path__card {
  min-width: 288px;
  min-height: 288px;
  flex-direction: column;
  border: 1px solid #E6E9ED;
  border-radius: 16px;
  padding: $size-m;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 368px;
  flex: auto;

  &-title {
    font-family: "Inter";
  }

  @include mq_l {
    width: 100%;
  }

  h2 {
    display: flex;
    align-self: center;
  }

  &__chart {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    h2 {
      align-self: center;
    }

    &__chart__label {
      color: #A0A7B1;
      font-family: "Inter", sans-serif;
      font-size: $font-size-s;
      white-space: nowrap;
    }
  }
}

</style>
