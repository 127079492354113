<template>
  <div
    v-if="!hasError"
    :border-radius="mq_m ? '0' : $tokens.border_radius_m"
  >
    <div class="hit-rate-report__progress">
      <h4>
        Média de acertos
      </h4>
      <div
        v-if="!loading"
        class="hit-rate-report__progress__chart"
      >
        <h2>{{ hitRate }}%</h2>
        <ApexCharts
          class="hit-rate-report__progress__chart__apexchart"
          type="bar"
          :options="chartOptions"
          :series="chartSeries"
          height="60px"
          width="375px"
        />
        <p
          class="hit-rate-report__progress__chart__label"
        >
          Total de acertos dividido pelos estudantes
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApexCharts from 'vue-apexcharts'
import statisticsApi from '@/service/statistics'
import mediaQueries from 'shared/mixins/mediaQueries'

const noHitRateLabelVariations = {
  notFinished: 'no-student-finished',
  notHit: 'no-student-hit',
  empty: '',
}

const emptyHitRateLabelVariations = {
  notFinished: 'Nenhum aluno finalizou',
  notHit: 'Nenhum aluno acertou',
}

export default {
  name: 'HitRateReport',
  components: {
    ApexCharts,
  },
  mixins: [ mediaQueries ],
  props: {
    noHitRateLabel: {
      type: String,
      default: noHitRateLabelVariations.notHit,
      validator: (value) => Object.values(noHitRateLabelVariations).includes(value),
    },
  },
  data() {
    return {
      hitRate: 0,
      loading: false,
      hasError: false,
    }
  },
  computed: {
    ...mapGetters([
      'yearSelectedContent',
    ]),
    chartSeries() {
      return [
        {
          name: 'Acertos',
          data: [ this.hitRate ],
        },
      ]
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            barHeight: '10px',
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: [ '#54C985' ],
          opacity: 1,
        },
        xaxis: {
          max: 100,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          show: false,
        },
        yaxis: {
          show: false,
        },

        grid: {
          show: false,
          borderColor: '#fff',
          row: {
            colors: [ '#F7F9FA' ],
          },
        },
        tooltip: {
          enabled: false,
        },
      }
    },
    selectedClassroom() {
      return this.$route.params.classroomId
    },
    emptyHitRateLabel() {
      switch (this.noHitRateLabel) {
        case noHitRateLabelVariations.notFinished: return emptyHitRateLabelVariations.notFinished
        case noHitRateLabelVariations.notHit: return emptyHitRateLabelVariations.notHit
        case noHitRateLabelVariations.empty: return emptyHitRateLabelVariations.notHit
        default: return emptyHitRateLabelVariations.notHit
      }
    },
  },
  watch: {
    selectedClassroom() {
      this.fetchPerformanceRate()
    },
  },
  async created() {
    this.fetchPerformanceRate()
  },
  methods: {
    async fetchPerformanceRate() {
      const { questionnaireCode, classroomId } = this.$route.params
      const currentYear = new Date().getFullYear()

      this.loading = true
      try {
        const { data } = await statisticsApi.getPerformanceRate({
          questionnaireCode,
          classroomId,
          contentYear: this.yearSelectedContent ?? currentYear,
        })

        this.hitRate = Math.round(data)
      } catch {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hit-rate-report {
  &__progress {
    font-family: "Inter", sans-serif;
    border: 1px solid #E6E9ED;
    border-radius: 16px;
    padding: $size-m;
    width: 100%;
    height: 100%;

    @include mq_s {
      width: 91%;
    }

    &__chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      h2 {
        font-size: 3rem;
        font-weight: 700;
        margin-top: 5rem;
        font-family: "Inter", sans-serif;
      }
    }

    &__chart__label {
      color: #A0A7B1;
      font-family: "Inter", sans-serif;
    }

    h4 {
      font-family: "Inter", sans-serif;
    }
  }

  &__empty {
    color: $color-ink-light;
    margin-top: $size-xs;
  }
}
</style>
