<template>
  <div class="assignments-list">
    <h3
      v-if="title"
      class="assignments-list__title"
    >
      <skeleton-loader width="150px" />
    </h3>
    <div
      v-for="index in 9"
      :key="index"
      class="assignments-list__item"
    >
      <div
        elevation="1"
        padding="16px"
        class="assignments-list__item__card"
      >
        <div class="assignments-list__item__card__details">
          <span class="assignments-skeleton">
            <skeleton-loader />
          </span>
          <span class="title-skeleton">
            <skeleton-loader />
          </span>
        </div>
        <div
          v-if="Number(buttons) > 0"
          class="button-skeleton"
        >
          <skeleton-loader width="100px" />
          <skeleton-loader
            v-if="Number(buttons) === 2"
            width="100px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssignmentsListSkeleton',
  props: {
    title: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: String,
      default: '1',
    },
  },
}
</script>

<style lang="sass" scoped>
=centered
  display: flex
  justify-content: center
  align-items: center

.assignments-list
  position: relative
  width: 100%

  &__title
    display: block
    width: 90px
    margin-bottom: $size-s

    .sas-skeleton-loader
      height: 23px !important

      +mq-l--mf
        height: 29px !important

  &__item
    margin-bottom: $size-s

    &__card
      flex-flow: row nowrap
      justify-content: space-between
      align-items: center
      border: 1px solid #E6E9ED
      border-radius: 16px

      +mq-m--mf
        display: flex
        padding: $size-m !important

      &__details
        .assignments-skeleton
          display: block
          width: 150px
          margin-bottom: 10px

        .title-skeleton
          display: block
          width: 300px
          margin-top: 14px

          .sas-skeleton-loader
            height: 21px !important

          +mq_s
            width: 200px

          +mq_m
            margin-bottom: 10px

      .button-skeleton
        display: inline-flex
        gap: $size-xs

        .sas-skeleton-loader
          height: 36px !important
</style>
