import http from './http'
import apiBff from './apiBff'

const currentYear = new Date().getFullYear()

export default {
  getStudentsStatistics: ({
    questionnaireCode,
    classroomId,
    contentYear = currentYear,
  }) => apiBff.get(
    `/reports/questionnaires/${questionnaireCode}/classrooms/${classroomId}/students-statistics?contentYear=${contentYear}`
  ),
  getQuestionsStatistics: async ({
    questionnaireCode,
    classroomId,
    classroomDifficulties = false,
    contentYear = currentYear,
  }) => {
    const url = `reports/questionnaires/${questionnaireCode}/classrooms/${classroomId}/questions-statistics?contentYear=${contentYear}`
    const params = {}

    if (classroomDifficulties) {
      params.classroomDifficulties = classroomDifficulties
    }

    const response = await apiBff.get(url, { params })

    return response.data
  },
  getQuestionnaire: async (questionnaireCode) => {
    const response = await http.get(`questionnaires/${questionnaireCode}`)

    return {
      ...response.data,
      isChallengeReinforcement: response.data.type === 'challenge_reinforcement',
      isChallengeActivity: response.data.type === 'challenge_activity',
      isTeacherChallenge: response.data.type === 'teacher_challenge',
      isHighSchool: response.data.type === 'high_school',
      isClassroomActivity: response.data.type === 'classroom_activity',
      isExtraActivity: response.data.type === 'extra_activity',
      isEnemByDifficulty: response.data.type === 'enem_by_difficulty',
      isMiddleSchool: response.data.type === 'middle_school',
    }
  },
  getPerformanceRate: ({ questionnaireCode, classroomId, contentYear = currentYear }) => http.get(
    `/reports/questionnaires/${questionnaireCode}/classrooms/${classroomId}/performance-rate?contentYear=${contentYear}`
  ),
  getEngagementStatistics: ({
    questionnaireCode,
    classroomId,
    contentYear = currentYear,
  }) => http.get(
    `/reports/questionnaires/${questionnaireCode}/classrooms/${classroomId}/engagement?contentYear=${contentYear}`
  ),
  getEngagementStatisticsBff: ({
    questionnaireCode,
    classroomId,
    contentYear = currentYear,
  }) => apiBff.get(
    `/reports/questionnaires/${questionnaireCode}/classrooms/${classroomId}/engagement?contentYear=${contentYear}`
  ),
}
