export default {
  methods: {
    isEscKeyPressed(event) {
      if (event.defaultPrevented) {
        return false
      }
      const key = event.key || event.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 37) {
        return true
      }

      return false
    },
  },
}
