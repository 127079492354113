<template>
  <div class="question-drawer-details">
    <div class="question-drawer-details__visible">
      <div
        v-if="mustShowSubject"
        class="question-drawer-details__visible__content"
      >
        <template v-if="loading">
          <skeleton-loader
            class="question-drawer-details__label"
            width="70px"
            height="15px"
          />
          <skeleton-loader
            width="120px"
            height="22px"
          />
        </template>
        <template v-else>
          <span class="question-drawer-details__label">
            {{ $t('report.questionsDrawer.contents') }}
          </span>
          <p>{{ content }}</p>
        </template>
      </div>
      <div
        v-if="question.level"
        class="question-drawer-details__visible__level"
      >
        <template v-if="loading">
          <skeleton-loader
            class="question-drawer-details__label"
            width="70px"
            height="15px"
          />
          <skeleton-loader
            width="60px"
            height="22px"
          />
        </template>
        <template v-else>
          <p class="question-drawer-details__skill">
            <s-badge>
              {{ $t(`levels.${question.level}`) }}
            </s-badge>
          </p>
          <div
            v-if="question.skill"
            class="question-drawer-details__skill"
          >
            <s-badge>
              {{ question.skill.code }}
            </s-badge>
          </div>
        </template>
      </div>
      <SButton
        variation="tertiary"
        :disabled="disabledReport"
        class="question-drawer-details__report-problem"
        @click="isReportProblemVisible = true"
      >
        <img
          :src="flagIcon"
          alt="Flag icon"
        >
        <p> Reportar problema </p>
      </SButton>
    </div>
    <ReportProblem
      v-if="isReportProblemVisible"
      @close="isReportProblemVisible = false"
      @report-succeed="$emit('report-succeed')"
    />
  </div>
</template>

<script>
import { SButton } from '@sas-te/alfabeto-vue'
import mediaQueries from 'shared/mixins/mediaQueries'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import ReportProblem from 'MFE/components/ReportProblem/ReportProblem'
import FlagIcon from '../../assets/icons/flag.svg'

export default {
  name: 'QuestionDrawerDetails',
  components: {
    SButton,
    ReportProblem,
  },
  mixins: [ mediaQueries, subjectVisibilityToggle ],
  props: {
    question: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggle: false,
      isReportProblemVisible: false,
    }
  },
  computed: {
    content() {
      return this.question.content
        ? this.question.content.name
        : '-'
    },
    flagIcon() {
      return FlagIcon
    },
    skillContent() {
      return this.question.skill
        && this.question.skill.content
        ? this.question.skill.content.name
        : '-'
    },
    skill() {
      return this.question.skill
        ? this.question.skill.name
        : '-'
    },
  },
  methods: {
    toggleDetails() {
      this.toggle = !this.toggle
    },
  },
}
</script>

<!-- eslint-disable max-len -->
<style lang="scss" scoped>
.question-drawer-details {
  padding: 20px $size-xl;
  position: relative;

  ::v-deep .sas-button.--active {
    background: rgba($color-ink, .25);
  }

  &__label {
    color: $color-ink-light;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    display: inline-block;
    margin-bottom: $size-xxs;
  }

  &__skill {
    ::v-deep .sas-badge.sas-badge--neutral {
      background: #C9CED4;
      color: #525860;
    }
  }

  &__report-problem {
    ::v-deep .sas-button__text {
      font-family: "Inter";
      display: flex;
      gap: $size-xxs;
      color: #707780;
    }
  }

  &__visible {
    @include mq-m--mf {
      @include flex-center-start;
    }

    &__content {
      margin-bottom: $size-s;

      @include mq-m--mf {
        margin-right: $size-xxl;
      }
    }

    &__level {
      display: flex;
      gap: $size-m;

      @include mq-m--mf {
        flex-grow: 1;
      }
    }
    &__toggle {
      position: absolute;
      top: $size-xs;
      right: $size-xs;
    }
  }

  &__collapsible {
    overflow: hidden;

    @include mq-m--mf {
      padding-top: $size-l;
    }

    &__object {
      margin-bottom: $size-s;

      @include mq-m--mf {
        margin-bottom: $size-l;
      }
    }
  }
}
</style>
