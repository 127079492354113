<template>
  <div class="report-section-layout">
    <div class="report-section-layout__header">
      <slot name="header">
        <div class="report-section-layout__header">
          <div class="report-section-layout__header__title">
            <skeleton-loader
              v-if="loading"
              width="350px"
              height="38px"
            />
            <h3 v-else>
              {{ title }}
            </h3>
          </div>
          <div class="report-section-layout__header__subtitle">
            <skeleton-loader
              v-if="loading"
              width="100px"
              height="22px"
            />
            <p v-else>
              {{ subtitle }}
            </p>
          </div>
          <slot name="top-bar" />
        </div>
      </slot>
    </div>
    <div class="report-section-layout__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportSectionLayout',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasBarSlot() {
      return Object.keys(this.$slots).includes('top-bar')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-section-layout {
  margin-bottom: $size-l;
  * {
    font-family: "Inter",
  }

  &__header {
    margin: $size-m 0;
    margin-bottom: $size-xs;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    &__subtitle {
      color: $color-ink-light;
      margin-bottom: $size-s;
    }
  }

  &__top-bar {
    margin-bottom: $size-s;
  }

  ::v-deep .sas-button.--secondary {
    box-shadow: none;
    @include flex-center;
  }
}
</style>
