<template>
  <div class="report-tabs">
    <template v-if="loading">
      <skeleton-loader
        width="80px"
        height="20px"
      />
    </template>
    <template v-else>
      <Tabs :tabs="teacherTabs" />
    </template>
  </div>
</template>

<script>
import Tabs from 'App/components/Tabs'

export default {
  name: 'ReportTabs',
  components: {
    Tabs,
  },
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    tabRoutes: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    teacherTabs() {
      return Object.entries(this.tabRoutes)
        .map(([ name, route ]) => ({
          name: this.$t(`report.tabs.${name}`),
          route,
        }))
    },
    isNotMiddleSchool() {
      if (!this.questionnaire.data) {
        return false
      }

      const { type } = this.questionnaire.data

      return !(type === 'middle_school')
    },
  },
}
</script>
