<template>
  <div class="student-details-questions-table">
    <s-table
      ref="questionsTable"
      :content="questions"
      :selected-row="activeQuestion"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="true"
      @click-row="clickRow"
    >
      <template
        slot="number"
        slot-scope="{ row }"
      >
        <h6 class="student-details-questions-table__number">
          {{ $t(
            'report.questionsDrawer.questionNumber',
            { number: row.number }
          ) }}
        </h6>
        <span class="student-details-questions-table__statement">
          {{ row.statement | truncate(statementMaxLength) }}
        </span>
      </template>
      <template
        v-if="mustShowSubject"
        slot="content"
        slot-scope="{ row }"
      >
        <span class="student-details-questions-table__content">
          {{ row.content.name }}
        </span>
      </template>
      <template
        slot="level"
        slot-scope="{ row }"
      >
        <span class="student-details-questions-table__level">
          {{ $t(
            `levels.${row.level}`
          ) }}
        </span>
      </template>
      <template
        slot="markedOption"
        slot-scope="{ row }"
      >
        <span class="student-details-questions-table__level">
          {{ letterIndex(Number(row.markedOption)) }}
        </span>
      </template>
      <template
        slot="answerKey"
        slot-scope="{ row }"
      >
        <span class="student-details-questions-table__level">
          {{ letterIndex(Number(row.answerKey)) }}
        </span>
      </template>
      <template
        slot="correct"
        slot-scope="{ row }"
      >
        <div class="student-details-questions-table__hit">
          <AnswerStatusBadge
            :row="row"
            :tooltip="handleTooltip(row)"
          />
        </div>
      </template>
    </s-table>
  </div>
</template>
<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import badgeStyle from 'App/mixins/badgeStyle'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import STable from 'MFE/components/STable'
import answerStatusEnum from 'App/enums/answerStatus'
import truncate from 'shared/utils/truncateText'
import isEmpty from 'lodash/isEmpty'
import AnswerStatusBadge from './components/AnswerStatusBadge'

export default {
  name: 'StudentDetailsQuestionsTable',
  components: {
    STable,
    AnswerStatusBadge,
  },
  mixins: [ mediaQueries, badgeStyle, subjectVisibilityToggle ],
  props: {
    questions: {
      type: Array,
      required: true,
    },
    activeQuestion: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      fields: [
        {
          value: 'number',
          text: 'Enunciado',
          sortable: true,
        },
        {
          value: 'level',
          text: 'Dificuldade',
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'markedOption',
          text: 'Alternativa escolhida',
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'answerKey',
          text: 'Alternativa correta',
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'correct',
          text: this.$t('report.questionsDrawer.answer'),
          sortable: true,
        },
      ],
      defaultSort: {
        field: 'number',
        direction: 'asc',
      },
    }
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 25
        case 'small':
          return 32
        case 'medium':
          return 58
        case 'large':
          return 68
        case 'x_large':
          return 100
        default:
          return 68
      }
    },
  },
  created() {
    this.addContentFieldOnSecondPosition()
  },
  methods: {
    truncate,
    generateMarkedOption(markedOptions) {
      if (isEmpty(markedOptions)) return []

      return markedOptions.map((option, index) => ({
        [`markedOption${this.letterIndex(index)}`]: option.rate,
        [`chose${this.letterIndex(index)}`]: option.rate,
      }))
    },
    letterIndex(index) {
      return String.fromCharCode(65 + index)
    },
    addContentFieldOnSecondPosition() {
      if (this.mustShowSubject) {
        const contentField = {
          value: 'content',
          text: this.$t('report.questionsDrawer.content'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        }

        this.fields.splice(1, 0, contentField)
      }
    },
    clickRow(event) {
      this.$emit('selectQuestion', {
        data: event.data,
        index: event.dataIndex,
      })
    },
    previousQuestion() {
      this.$refs.questionsTable.previous()
    },
    nextQuestion() {
      this.$refs.questionsTable.next()
    },
    handleTooltip(row) {
      const { answerStatus } = row
      switch (answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'Resposta correta'
        case answerStatusEnum.WRONG:
          return 'Resposta incorreta'
        case answerStatusEnum.BLANK:
          return 'Resposta em branco'
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.student-details-questions-table {
  background-color: $color-white;
  margin-top: $size-m;

  &__number {
   font-family: "Inter";
  }

  @include mq-s--mf {
    &__number {
      margin-bottom: $size-xxs;
    }

    &__statement {
      color: $color-ink-light;
    }
  }

  ::v-deep .sas-table__body tr {
    cursor: pointer;
  }
}
</style>
