<template>
  <div class="students-view-cards">
    <div
      v-for="(item, index) in students"
      :key="index"
      class="students-view-cards__item"
      @click="selectStudent(item, index)"
    >
      <div class="students-view-cards__item__student">
        <ProfilePicture
          :user="item"
        />
        <h6 class="students-view-cards__item__student__name">
          {{ item.name }}
        </h6>
      </div>
      <div class="students-view-cards__item__student__data">
        <div class="students-view-cards__item__student__status">
          <h6 class="students-view-cards__item__student__status__title">
            Diagnóstico
          </h6>
          <s-badge
            :variation="getVariation(item.diagnosticStatus)"
          >
            {{ handleDiagnosticStatusText(item.diagnosticStatus) }}
          </s-badge>
        </div>

        <div class="students-view-cards__item__student__status">
          <h6 class="students-view-cards__item__student__status__title">
            Resgate de conhecimento
          </h6>
          <s-badge
            :variation="getFinishedStatusVariation(item.status)"
          >
            <Icon
              :type="iconType(item.status)"
            />
            {{ getStatusText(item.status) }}
          </s-badge>
        </div>

        <div class="students-view-cards__item__student__status">
          <h6 class="students-view-cards__item__student__status__title">
            Resgates recomendados
          </h6>
          <div class="students-view-cards__item__student__retry-count">
            {{ recoveryAbleRecommendedText(item) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusVariation, studentAssignmentStatus } from 'MFE/utils/status'
import formatDate from 'App/mixins/formatDate'
import ProfilePicture from 'MFE/components/ProfilePicture'

const statusTextEnum = {
  FINISHED: 'Concluído',
  NOT_FINISHED: 'Pendente',
  NOT_REQUIRED: '',
}

const diagnosticStatusText = {
  ABLE: 'Apto',
  NOT_ABLE: 'Não apto',
  ONGOING: 'Em andamento',
  NOT_STARTED: 'Não iniciado',
}

export default {
  name: 'RecompositionKnowledgeTestCard',
  components: {
    ProfilePicture,
  },
  mixins: [
    formatDate,
  ],
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      studentAssignmentStatus,
    }
  },
  methods: {
    getStatusVariation,
    selectStudent(data, index) {
      this.$emit('selectStudent', {
        data,
        index,
      })
    },
    iconType(status) {
      switch (status) {
        case 'FINISHED': return 'check-circle'
        case 'NOT_FINISHED': return 'alert-circle'
        default: return null
      }
    },
    recoveryAbleRecommendedText(row) {
      if (row.able === null || row.recommended === null) return '-'

      return `${row.able} / ${row.recommended} aptos`
    },
    getFinishedStatusVariation(finishedStatus) {
      switch (finishedStatus) {
        case 'FINISHED': return 'success'
        case 'NOT_FINISHED': return 'primary'
        case 'NOT_REQUIRED': return 'neutral'
        default: return null
      }
    },
    getStatusText(finishedStatus) {
      return statusTextEnum[finishedStatus] || ''
    },
    handleDiagnosticStatusText(diagnosticStatus) {
      return `• ${diagnosticStatusText[diagnosticStatus]}` || ''
    },
    getVariation(status) {
      switch (status) {
        case 'ABLE': return 'success'
        case 'NOT_ABLE': return 'danger'
        case 'ONGOING': return 'warning'
        case 'NOT_STARTED': return 'neutral'
        default: return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.students-view-cards {
  &__item {
    border: 1px solid #E6E9ED;
    border-radius: 16px;
    padding: $size-xs;
    gap: $size-s;
    display: flex;
    flex-direction: column;

    &__student {
      display: flex;
      gap: $size-s;
      padding: $size-s;
      align-items: center;
      border-bottom: 1px solid #E6E9ED;

      &__data {
        display: flex;
        justify-content: space-between;
        padding: 0 $size-s;

        @include mq_s {
          flex-direction: column;
          gap: $size-m;
        }
      }

      &__status {
        margin-bottom: $size-xs;

        &__title {
          font-family: "Inter";
          font-size: 14px;
          font-weight: 400;
          line-height: 17.5px;
          color: #707780;
          margin-bottom: $size-xs;
        }

        ::v-deep .sas-badge--danger,
        ::v-deep .sas.badge--success,
        ::v-deep .sas-badge--neutral {
          background: none;
        }
      }

      ::v-deep .sas-badge__text {
        align-items: center;
        display: flex;
        padding: 5px 10px;
        font-size: 700;

        i {
          color: #C78500;

          svg {
            max-width: 20px;
            max-height: 20px;
          }
        }
      }
    }
  }

}
</style>
